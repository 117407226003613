import { Auth } from "../../auth";
import { Chat } from "../../chats";
import { _t } from "../../localization";
import { User_Summary_Data } from "../../users";
import { Popup } from "./popup";

export class Chat_Member_Popup extends Popup
{
    add_cb?: (chat: Chat, target_user: User_Summary_Data) => void;
    leave_cb?: (chat: Chat, target_user: User_Summary_Data) => void;
    make_admin_cb?: (chat: Chat, target_user: User_Summary_Data) => void;
    remove_cb?: (chat: Chat, target_user: User_Summary_Data) => void;
    view_info_cb?: (target_user: User_Summary_Data) => void;

    constructor(chat: Chat, target_user: User_Summary_Data)
    {
        super('chat_member', { title: _t('general/options') });

        this._chat = chat;
        this._target_user = target_user;

        if( !Auth.current_user )
            return;

        const view_info_button = document.createElement('button');
        view_info_button.className = 'menu-item';
        view_info_button.innerHTML = _t('general/info');
        view_info_button.addEventListener('click', () => {
            this.close();
            if( this.view_info_cb )
                this.view_info_cb(target_user);
        });
        this._body.append(view_info_button);

        if(
            this._chat.member_role === 'admin' &&
            target_user.chat_status === 'active' &&
            target_user.uid !== Auth.current_user.uid
        ){
            const make_admin_button = document.createElement('button');
            make_admin_button.className = 'menu-item';
            make_admin_button.innerHTML = _t('chats/make_admin');
            make_admin_button.addEventListener('click', () => {
                if( this.make_admin_cb )
                    this.make_admin_cb(chat, target_user);
                this.close();
            });
            this._body.append(make_admin_button);
        }

        if( target_user.uid === Auth.current_user.uid ){
            const leave_button = document.createElement('button');
            leave_button.className = 'menu-item danger';
            leave_button.innerHTML = _t('chats/leave');
            leave_button.addEventListener('click', () => {
                if( this.leave_cb )
                    this.leave_cb(chat, target_user);
                this.close();
            });
            this._body.append(leave_button);
        }

        if(
            this._chat.member_role === 'admin' &&
            this._target_user.chat_status === 'active' &&
            target_user.uid !== Auth.current_user.uid
        ){
            const delete_button = document.createElement('button');
            delete_button.className = 'menu-item danger';
            delete_button.innerHTML = _t('general/remove');
            delete_button.addEventListener('click', () => {
                if( this.remove_cb )
                    this.remove_cb(chat, target_user);
                this.close();
            });
            this._body.append(delete_button);
        }

        if(
            this._chat.member_role === 'admin' &&
            (this._target_user.chat_status === 'removed' || this._target_user.chat_status === 'left')
        ){
            const add_button = document.createElement('button');
            add_button.className = 'menu-item';
            add_button.innerHTML = _t('general/add');
            add_button.addEventListener('click', () => {
                if( this.add_cb )
                    this.add_cb(chat, target_user);
                this.close();
            });
            this._body.append(add_button);
        }
    }

    private _chat: Chat;
    private _target_user: User_Summary_Data;
}
