import * as io from "socket.io-client";

import { API, API_Data, parse_API_messages } from "./api";
import * as core from "./core";
import { Updater } from "./updater";

const NOTIFICATION_LIMIT = 20;

export class Notification
{
    id = 0;
    name = '';
    content = '';
    image = '';
    url = '';
    status = '';
    created?: Date;
}

export class Notifications
{
    static async get(data: any = {}, force_cache = false, log: core.Log_Messages = {}): Promise<Map<number, Notification> | undefined>
    {
        const cached_notifications = this._get_from_cache(data.before);
        if( force_cache || cached_notifications.size > 0 )
            return cached_notifications;

        if( this._all_loaded )
            return new Map;

        const response = await API.GET('/notifications', data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        const notifications = new Map<number, Notification>;
        
        for(const idx in result.data.notifications){
            const notification = this.parse(result.data.notifications[idx]);
            if( notification ){
                notifications.set(notification.id, notification);
                this._cache.set(notification.id, notification);
            }
        }

        if( notifications.size === 0 )
            this._all_loaded = true;

        return notifications;
    }

    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-notification-image.png';
    }

    static async has_unseen(force_cache = false): Promise<boolean>
    {
        const notifications = await this.get({}, force_cache);
        if( !notifications )
            return false;

        for(const notification of notifications?.values()){
            if( notification.status === 'new' )
                return true;
        }

        return false;
    }

    static async init(socket: io.Socket, updater: Updater): Promise<void>
    {
        this._updater = updater;
        socket.on('notifications/received', this._on_notification_received.bind(this));
    }

    static async mark_as_seen(log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT('/notifications/mark_as_seen');
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return false;

        this._cache.forEach(notification => {
            if( notification.status === 'new' )
                notification.status = 'seen'
        });

        return true;
    }

    static parse(data: any, notification: Notification | false = false): Notification | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !notification )
            notification = new Notification;

        notification.id = data.id || '';
        notification.name = data.name || '';
        notification.content = data.content || '';
        notification.image = data.image || '';
        notification.url = data.url || '';
        notification.status = data.status || '';
        notification.created = core.UTC(data.created);

        return notification;
    }

    static async update(notification: Notification, data: API_Data, log: core.Log_Messages = {}): Promise<Notification | undefined>
    {
        const response = await API.PUT('/notifications/' + encodeURIComponent(notification.id), data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )
            return;

        const updated_notification = this.parse(result.data.notification);
        if( !updated_notification )
            return;
            
        this._cache.set(updated_notification.id, updated_notification);

        return updated_notification;
    }

    static async update_before(notification: Notification, data: API_Data, log: core.Log_Messages = {}): Promise<boolean>
    {
        const response = await API.PUT('/notifications/before/' + encodeURIComponent(notification.id), data);
        if( !response )
            return false;

        const result = await response.json();
        parse_API_messages(log, result);
        return response.status === 200;
    }

    private static _all_loaded = false;
    private static _cache = new Map<number, Notification>;
    private static _updater: Updater;

    private static _get_from_cache(before?: number): Map<number, Notification>
    {
        const notifications = new Map<number, Notification>;
        for(const [id, notification] of this._cache){
            if( !before || id < before )
                notifications.set(id, notification);

            if( notifications.size >= NOTIFICATION_LIMIT )
                break;
        }

        return notifications;
    }

    private static _on_notification_received(data: any)
    {
        if( typeof data !== 'object' )
            return;

        const notification = this.parse(data.notification);
        if( !notification )
            return;

        this._cache.set(notification.id, notification);
        this._cache = new Map([...this._cache.entries()].sort((a,b) => b[1].id - a[1].id))

        dispatchEvent(
            new CustomEvent('receive_notification', { detail: {notification: notification} })
        );

        setTimeout(() => {
            this._updater.run();
        }, 0);
    }
}
