import { _t } from "../localization";
import { print_global_message } from "./global_message";

export class Image_Picker
{
    element: HTMLDivElement;
    file?: File;
    image = '';

    constructor()
    {
        this.element = document.createElement('div');
        this.element.className = 'image-picker';
        this.element.style.border = '1px solid var(--light-detail-color)';
        this.element.style.borderRadius = '10px';
        this.element.style.overflow = 'hidden';

        this._upload_panel = document.createElement('div');
        this._upload_panel.className = 'uploader';
        this._upload_panel.style.padding = '20px';
        this.element.append(this._upload_panel);

        this._upload_input = document.createElement('input');
        this._upload_input.accept = 'image/*';
        this._upload_input.style.display = 'block';
        this._upload_input.style.margin = 'auto';
        this._upload_input.type = 'file';
        this._upload_input.addEventListener('change', this._on_upload_image.bind(this));
        this._upload_panel.append(this._upload_input);

        this._recommendation_element = document.createElement('div');
        this._recommendation_element.style.margin = 'auto';
        this._upload_panel.append(this._recommendation_element);

        this._editor_panel = document.createElement('div');
        this._editor_panel.className = 'editor text-center';

        this._image_element = document.createElement('img');
        this._image_element.style.display = 'block';
        this._image_element.style.width = '100%';
        this._editor_panel.append(this._image_element);

        const back_element = document.createElement('a');
        back_element.innerHTML = _t('media/select_other_image');
        back_element.style.display = 'block';
        back_element.style.margin = '10px';
        back_element.addEventListener('click', this._on_back_click.bind(this));
        this._editor_panel.append(back_element);
    }

    switch_to(panel: string)
    {
        if( panel === 'uploader' ){
            this.element.innerHTML = '';
            this.element.append(this._upload_panel);
        }else if( panel === 'editor' ){
            this.element.innerHTML = '';
            this.element.append(this._editor_panel);
            this._image_element.src = this.image;
        }
    }

    _editor_panel: HTMLDivElement;
    _image_element: HTMLImageElement;
    _recommendation_element: HTMLDivElement;
    _upload_input: HTMLInputElement;
    _upload_panel: HTMLDivElement;

    _on_back_click(_: Event)
    {
        this.file = undefined;
        this._upload_input.value = '';
        this.switch_to('uploader');
    }

    _on_upload_image(event: Event)
    {
        if( !event.target || !(event.target instanceof HTMLInputElement) )
            return;

        const input = event.target;
        if( !input.files || !input.files.length )
            return;

        const file = input.files[0];
        if( file.type !== 'image/jpeg' && file.type !== 'image/png' ){
            print_global_message('error', _t('media/invalid_image_type'));
            return;
        }

        const reader = new FileReader();
        reader.onload = activity => {
            if( !activity.target || !activity.target.result ){
                print_global_message('error', _t('media/load_error'));
                return;
            }

            this.file = file;
            this.image = activity.target.result as string;
            this.switch_to('editor');
        }
        reader.readAsDataURL(file);
    }
}
