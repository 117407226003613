const globals = {
    APP_URL: 'https://app.wuddys.com',
    SERVER_URL: 'https://api.wuddys.com', // Main server.
    // SERVER_URL: 'https://dev.api.wuddys.com', // Dev server.
    // SERVER_URL: 'https://localhost:3000', // Local server.
    AUTH_PATH: 'auth',
    HOME_PATH: 'activities/discover',
    
    PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.wuddys.app',
};

export default globals;
