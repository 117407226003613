import { sanitize } from "dompurify";

import { is_log_message_type, Log_Message_Type, Log_Messages } from "../core";

export class Global_Message
{
    constructor(messages: Log_Messages, type: Log_Message_Type)
    {
        this._element = document.createElement('div');
        this._element.className = 'page-center-content global-message ' + sanitize(type);

        if( Object.keys(messages).length === 1 ){
            this._element.textContent = Object.values(messages)[0].message;
        }else if( Object.keys(messages).length > 1 ){

            const list = document.createElement('ul');
            this._element.append(list);

            for(const code in messages){
                const item = document.createElement('li');
                item.textContent = messages[code].message;
                list.append(item);
            }
        }
    }

    open()
    {
        const error_container = Global_Message.get_container_element();
        error_container.append(this._element);

        this._element.style.opacity = '0';
        setTimeout(() => this._element.style.opacity = '1', 200);
        setTimeout(this.close.bind(this), 5000);
        setTimeout(() => document.body.addEventListener(
            'click',
            () => { this.close() },
            {'once': true}
        ), 1); // Wait for a short moment to avoid being triggered by a current click activity.
    }

    close()
    {
        this._element.style.opacity = '0';
        setTimeout(() => this._element.remove(), 200);
    }

    static get_container_element()
    {
        let error_container = document.getElementById('global-error-container');
        if( error_container )
            return error_container;

        error_container = document.createElement('div');
        error_container.id = 'global-error-container';
        document.body.append(error_container);

        return error_container;
    }

    private _element: HTMLDivElement;
}

export function print_global_message(type: Log_Message_Type, messages: string)
{
    print_global_messages({[type]: {type: type, code: type, message: messages}});
}

export function print_global_messages(messages: Log_Messages)
{
    const partitions = new Map<Log_Message_Type, Log_Messages>;

    for(const code in messages){
        const data = messages[code];
        if( !is_log_message_type(data.type) )
            data.type = 'info';

        let partition = partitions.get(data.type);
        if( !partition ){
            partition = {};
            partitions.set(data.type, partition);
        }

        partition[code] = data;
    }

    for(const [type, messages] of partitions){
        const global_message = new Global_Message(messages, type);
        global_message.open();
    }
}
