export class Updater
{
    handles = new Array<() => void>

    constructor(update_callback: () => void)
    {
        this._update_callback = update_callback
    }

    run()
    {
        this._update_callback()
    }

    private _update_callback: () => void
}
