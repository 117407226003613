import { sanitize } from 'dompurify';

import { locales } from "../localization";

export class Language_Input
{
    element: HTMLSelectElement;

    constructor()
    {
        this.element = document.createElement('select');
        this.element.name = 'language';
        Object.entries(locales)
            .sort((a, b) => a[1].name.english > b[1].name.english ? 1 : -1)
            .forEach(element => { this.element.innerHTML += `<option value="${sanitize(element[0])}">${sanitize(element[1].name.native)}</option>`; }
        );
    }

    get_value(): string
    {
        return this.element.value;
    }
}