import globals from './.env';
import * as core from './core';
import { print_global_message } from './ui/global_message';
import { Locale, _t } from './localization';
import { Log_Messages } from './core';

export type API_Data = {[key: string]: any};


export function parse_API_messages(buffer: Log_Messages, data: {'log': Record<string, any>})
{
    if( !data || !data.log )
        return;
    
    let code: keyof typeof data.log;
    for(code in data.log){
        if( !data.log.hasOwnProperty(code) )
            continue;
        
        const message = data.log[code]
        buffer[code] = {
            type: message.type,
            code: message.code,
            message: message.local_message
        };
    }
}

export class API
{
    static callbacks = new Array<(response: Response) => void>;
    static auth_token?: string;
    static uri = globals.SERVER_URL;

    static async GET(endpoint: string, data: API_Data = {}, args: {[key: string]: any} = {}): Promise<Response | undefined>
    {
        args['query'] = data;
        args['method'] = 'GET';
        return this.run(endpoint, args);
    }

    static async POST(endpoint: string, data: API_Data = {}, args: {[key: string]: any} = {}): Promise<Response | undefined>
    {
        args['body'] = data;
        args['method'] = 'POST';
        return this.run(endpoint, args);
    }

    static async PUT(endpoint: string, data: API_Data = {}, args: {[key: string]: any} = {}): Promise<Response | undefined>
    {
        args['body'] = data;
        args['method'] = 'PUT';
        return this.run(endpoint, args);
    }

    static async run(endpoint: string, args: {[key: string]: any}): Promise<Response | undefined>
    {
        if( !args['headers'] )
            args['headers'] = {};

        args['headers']['Accept-Language'] = Locale.language;

        let query = '';
        if( 'query' in args )
            query = core.serialize_query(args.query as API_Data);

        if( 'body' in args && typeof(args.body) === 'object' && !(args.body instanceof FormData) ){
            args.body = JSON.stringify(args.body);
            args['headers']['Content-Type'] = 'application/json';
        }

        if( this.auth_token )
            args['headers']['Authorization'] = 'Basic ' + btoa('_:' + this.auth_token);

        let uri = this.uri + endpoint;
        if( query )
            uri += '?' + query;
        
        return fetch(uri, args).then(response => {
            
            if( response.status >= 500 && response.status < 600 ){
                print_global_message('error', _t('general/servers_down'));
                return;
            }

            for(const action of API.callbacks)
                action(response);
            return response;
        }).catch((e) => {
            console.log(e)
            print_global_message('error', _t('general/no_connection'));
            return undefined;
        })
    }
}
