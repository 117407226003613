import { _t } from "../localization";
import { Scene } from "../scenes";
import { Updater } from "../updater";

export class Not_Found_Scene extends Scene
{
    form!: HTMLFormElement;

    constructor(updater: Updater)
    {
        super(updater, 'not_found');
    }

    async open()
    {
        await super.open();

        alert(_t('general/page_not_found'));
    }
}
