import { Auth } from "../auth";
import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import * as DOM from "../ui/DOM";
import { print_global_message } from "../ui/global_message";
import { Updater } from "../updater";
import { Notification, Notifications } from "../notifications";

export class Notifications_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'notifications');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();

        addEventListener('receive_notification', this._on_receive_notification.bind(this) as ((evt: Event) => Promise<void>));
    }

    async open()
    {
        await super.open();

        await Notifications.mark_as_seen();

        this._oldest_notification = undefined;
        this._loading_element.style.display = 'block';
        this._list_element.innerHTML = '';
        
        this._check_load_area();
    }

    private _list_element!: HTMLDivElement;
    private _loading_element!: HTMLDivElement;
    private _nothing_found_element!: HTMLDivElement;
    private _oldest_notification?: Notification;

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1 data-lang="notifications"></h1>
            <div class="notifications-container"></div>
            <div class="loading-area text-center" style="padding: 20px;">
                ${_t('general/loading')}
            </div>`;

        this._list_element = this.element.querySelector('.notifications-container') as HTMLDivElement;
        this._loading_element = this.element.querySelector('.loading-area') as HTMLDivElement;
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('p');
        this._nothing_found_element.className = 'text-center';
        this._nothing_found_element.innerHTML = _t('general/nothing_found');
    }

    private async _load_notifications()
    {
        if( !Auth.current_user )
            return;
        
        const notifications = await Notifications.get({'before': this._oldest_notification?.id ?? ''});
        if( !notifications ){
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        for(const [id, notification] of notifications){
            if( this._oldest_notification === undefined || id < this._oldest_notification.id )
                this._oldest_notification = notification;
            
            this._list_element.append(this._generate_notification_element(notification));
        }
        
        if( notifications.size === 0 ){
            this._loading_element.style.display = 'none';
            if( this._oldest_notification === undefined )
                this._list_element.append(this._nothing_found_element);
        }
    }

    private _check_load_area()
    {
        if( DOM.is_in_view(this._loading_element, document.body) )
            this._load_notifications();

        if( this.is_active )
            setTimeout(this._check_load_area.bind(this), 1000);
    }

    private async _on_notification_clicked(notification: Notification, element: HTMLElement)
    {
        Notifications.update(notification, {status: 'read'});

        if( !notification.url ){
            element.dataset.status = 'seen';
            return;
        }

        await Scene_Manager.open_url(notification.url);
        this._updater.run();
    }

    private async _on_receive_notification(event: CustomEvent): Promise<void>
    {
        if( !this.is_active )
            return;

        await Notifications.mark_as_seen();

        this._list_element.prepend(this._generate_notification_element(event.detail.notification as Notification));
    }

    private _generate_notification_element(notification: Notification): HTMLElement
    {
        const item_element = document.createElement('div');
        item_element.className = 'main-list-item notification-list-item button';
        item_element.dataset.id = notification.id.toString();
        item_element.dataset.status = notification.status;
        item_element.innerHTML = `
            <div class="image-container">
                <img src="${Notifications.get_image_uri(notification.image, 'main_list_item')}"/>
            </div>
            <div class="info-container" style="padding: 15px 0">
                ${notification.content}
            </div>`;
        item_element.addEventListener('click', this._on_notification_clicked.bind(this, notification, item_element));

        return item_element;
    }
}
