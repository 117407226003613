import { sanitize } from "dompurify";

import { Activity, Activities } from "../activities";
import { Auth } from "../auth";
import { print_date, UTC } from "../core";
import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { print_global_message } from "../ui/global_message";
import { Updater } from "../updater";

export class Activities_Scene extends Scene
{
    list_element!: HTMLDivElement;
    time_state: 'upcomming' | 'history' = 'upcomming';
    user?: 'me'; // `undefined` for all.

    constructor(updater: Updater)
    {
        super(updater, 'activities');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
        this._init_tools_element();
    }

    async update()
    {
        await this._load_activities();
        this._update_filters();
    }

    private _all_users_button!: HTMLButtonElement;
    private _current_user_button!: HTMLButtonElement;
    private _history_time_button!: HTMLButtonElement;
    private _nothing_found_element!: HTMLDivElement;
    private _upcomming_time_button!: HTMLButtonElement;

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1 data-lang="activities/title">Activities</h1>

            <div class="flex-equal no-content-side-padding" style="font-size: 0.9em">
                <div class="flex-equal horizontal-rounded-content" style="padding: 10px">
                    <button class="small" data-user="all" data-lang="activities/all">all</button>
                    <button class="small" data-user="me" data-lang="activities/mine">mine</button>
                </div>
                <div class="flex-equal horizontal-rounded-content" style="padding: 10px">
                    <button class="small" data-time="upcomming" data-lang="activities/upcomming">upcomming</button>
                    <button class="small" data-time="history" data-lang="activities/history">history</button>
                </div>
            </div>

            <div class="activities-container"></div>`;

        this._upcomming_time_button = this.element.querySelector("button[data-time='upcomming']")!;
        this._upcomming_time_button.addEventListener('click', () => { this.time_state = 'upcomming'; this._updater.run() });
        this._history_time_button = this.element.querySelector("button[data-time='history']")!;
        this._history_time_button.addEventListener('click', () => { this.time_state = 'history'; this._updater.run() });
        this._all_users_button = this.element.querySelector("button[data-user='all']")!;
        this._all_users_button.addEventListener('click', () => { this.user = undefined; this._updater.run() });
        this._current_user_button = this.element.querySelector("button[data-user='me']")!;
        this._current_user_button.addEventListener('click', () => { this.user = 'me'; this._updater.run() });

        this.list_element = this.element.querySelector('.activities-container') as HTMLDivElement;
    }

    private _init_tools_element()
    {
        this.tools_element = document.createElement('div');
        this.tools_element.className = "bottom-menu flex-equal page-center-content";
        this.tools_element.innerHTML = `
            <a id="nav-new-activity-button" class="button with-text">
                <img src="/assets/images/nav-new-activity.png">
                <span data-lang="activities/new_activity">new activity</span>
            </a>`;

        this.tools_element.querySelector('#nav-new-activity-button')!.addEventListener('click', async () => {
            await Scene_Manager.open('activities/create');
            this._updater.run();
        });
    }

    private async _load_activities()
    {
        if( !Auth.current_user ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        const messages = {};

        const activities = await Activities.search(messages);
        if( !activities ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this.list_element.innerHTML = '';

        let activities_to_print = new Array<Activity>;
        for(const activity of activities){

            const datetime = activity.datetime;
            
            if(
                !datetime || 
                this.time_state === 'upcomming' && datetime.getTime() - Date.now() <= 0 ||
                this.time_state === 'history' && datetime.getTime() - Date.now() > 0
            )
                continue;
            
            if( this.user === 'me' ){
                if( Auth.current_user.uid !== activity.user?.uid )
                    continue;
            }else if( this.user ){
                if( this.user !== activity.user?.uid )
                    continue;
            }

            activities_to_print.push(activity);
        }

        if( activities_to_print.length === 0 ){
            if( this.time_state === 'upcomming' )
                this.list_element.append(this._nothing_found_element);
            else
                this.list_element.innerHTML = '<p class="text-center">' + _t('general/nothing_found') + '</p>';
            return;
        }

        if( this.time_state === 'history' )
            activities_to_print.reverse();

        for(const activity of activities_to_print){

            let datetime = activity.datetime ? print_date(activity.datetime) : '';
            let deadline = activity.deadline ? print_date(activity.deadline) : '';

            let status_class = '';
            let deadline_code = `<div class="date"><strong>${_t('activities/deadline')}</strong> <em>${sanitize(deadline)}</em></div>`;
            let show_deadline = false;
            let note_code = '';

            // First check you own action, than the status as the status can change after your action.
            if( activity.action && activity.action.type === 'left' ){
                status_class = 'left';
                note_code = '<div class="note">' + _t('activities/left') + '</div>';
            }else if( activity.action && activity.action.type === 'expired' ){
                status_class = 'expired';
                note_code = '<div class="note">' + _t('activities/expired') + '</div>';
            }else if( activity.status === 'canceled' ){
                status_class = 'canceled';
                note_code = '<div class="note">' + _t('activities/canceled') + '</div>';
            }else if( activity.status === 'suspended' ){
                status_class = 'suspended';
                note_code = '<div class="note">' + _t('general/suspended') + '</div>';
            }else if( activity.action && activity.action.type === 'invited' ){
                status_class = 'invited';
                show_deadline = true;
                note_code = '<div class="note">' + _t('activities/invited') + '</div>';
            }else if( activity.action && activity.action.type === 'saved' ){
                status_class = 'saved';
                show_deadline = true;
                note_code = '<div class="note">' + _t('activities/saved') + '</div>';
            }else if( activity.status === 'active' && activity.action && activity.action.type === 'joined' ){
                if( activity.guest_count < activity.min_guests ){
                    if( activity.deadline && activity.deadline > UTC() ){
                        show_deadline = true;
                        note_code = `<div class="note">${sanitize(_t('activities/x_more_guests', activity.min_guests - activity.guest_count))}</div>`;
                    }
                }else{
                    status_class = 'success';
                }

                if(
                    activity.guest_count < activity.max_guests &&
                    activity.action.invitees && activity.action.invitees.length < activity.max_invitees &&
                    activity.deadline && activity.deadline > UTC()
                ){
                    note_code += '<div class="note">' + _t('activities/note/invite') + '</div>';
                    show_deadline = true;
                }
            }

            if( activity.deadline && activity.deadline.getTime() - Date.now() < 0 )
                show_deadline = false;
            
            const activity_element = document.createElement('div');
            activity_element.className = 'main-list-item activity-list-item button ' + status_class;
            activity_element.dataset.uid = activity.uid;
            activity_element.innerHTML = `
                <div class="image-container">
                    <img src="${sanitize(Activities.get_image_uri(activity.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${sanitize(activity.name)}</div>
                    <div class="date"><strong>${_t('activities/at_date')}</strong> <em>${sanitize(datetime)}</em></div>
                    ${show_deadline ? deadline_code : ''}
                    ${note_code}
                </div>`;

            activity_element.addEventListener('click', async () => {
                if( activity.status === 'suspended' ){
                    alert(_t('activities/suspended_explanation'));
                    return;
                }

                await Scene_Manager.open('activity', {'uid': activity.uid});
                this._updater.run();
            });

            this.list_element.append(activity_element);
        }
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = '<p class="text-center" data-lang="activities/no_upcomming">You have no upcoming activities.</p>';
        
        const create_activity_button = document.createElement('button');
        create_activity_button.dataset.lang = 'activities/no_upcomming_create';
        create_activity_button.className = 'full-width';
        create_activity_button.innerHTML = 'Host an activity';
        create_activity_button.style.marginBottom = '20px';
        create_activity_button.addEventListener('click', async () => {
            await Scene_Manager.open('activities/create');
            this._updater.run();
        });
        this._nothing_found_element.append(create_activity_button);

        const discover_button = document.createElement('button');
        discover_button.dataset.lang = 'activities/no_upcomming_discover';
        discover_button.className = 'full-width primary';
        discover_button.innerHTML = 'Discover activities to join';
        discover_button.addEventListener('click', async () => {
            await Scene_Manager.open('activities/discover');
            this._updater.run();
        });
        this._nothing_found_element.append(discover_button);
    }

    private _update_filters()
    {
        if( this.time_state === 'history' ){
            this._upcomming_time_button.classList.remove('primary');
            this._history_time_button.classList.add('primary');
        }else{
            this._upcomming_time_button.classList.add('primary');
            this._history_time_button.classList.remove('primary');
        }

        if( !this.user ){
            this._all_users_button.classList.add('primary');
            this._current_user_button.classList.remove('primary');
        }else if( this.user === 'me' ){
            this._all_users_button.classList.remove('primary');
            this._current_user_button.classList.add('primary');
        }else{
            this._all_users_button.classList.remove('primary');
            this._current_user_button.classList.remove('primary');
        }
    }
}
