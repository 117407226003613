import { Capacitor } from '@capacitor/core';

//
// Date.
//
export function date_to_local(input: string)
{
    return new Date(input + ' UTC').toLocaleString()
}

export function date_to_UTC(input: string)
{
    const date = new Date(input)

    const month = date.getUTCMonth() + 1

    let minutes = date.getUTCMinutes().toString()
    if( minutes.length < 2 )
        minutes = '0' + minutes

    let seconds = date.getUTCSeconds().toString()
    if( seconds.length < 2 )
        seconds = '0' + seconds

    return date.getUTCFullYear() + '-' + month + '-' + date.getUTCDate()
        + ' ' + date.getUTCHours().toString() + ':' + minutes + ':' + seconds
}

export function print_date(date: Date)
{
    return date.toLocaleString(undefined, {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        formatMatcher: 'best fit',
    });
}

export function UTC(): Date;
export function UTC(input: unknown): Date | Date;
export function UTC(input?: unknown): Date | undefined
{
    let date: Date;
    if( typeof input === 'string' ){
        if( !Number.isNaN(Number(input)) )
            date = new Date(Number(input) * 1000);
        else if( /^\d{4}-\d{1,2}-\d{1,2}$/.test(input) )
            date = new Date(input + ' 00:00:00Z');
        else if( /^\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{2}(:\d{2})?$/.test(input) )
            date = new Date(input + 'Z');
        else
            return;
    }else if( typeof input === 'number' ){
        date = new Date(input);
    }else if( input instanceof Date ){
        date = input;
    }else if( input === undefined ){
        date = new Date();
    }else{
        return;
    }
    
    return isNaN(date.getTime()) ? undefined : date;
}

//
// Device.
//
export function is_android(): boolean
{
    return navigator.userAgent.toLowerCase().includes('android') || Capacitor.getPlatform() === 'android';
}

export function is_ios(): boolean
{
    return navigator.userAgent.toLowerCase().includes('iphone') || Capacitor.getPlatform() === 'ios';
}

export function is_mobile(): boolean
{
    return is_android() || is_ios();
}

export function is_native(): boolean
{
    return Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios';
}

//
// Log Messages.
//
export type Log_Message_Type = 'error' | 'success' | 'warning' | 'info';

export function is_log_message_type(value: string): value is Log_Message_Type
{
    return value === 'error' || value === 'success' || value === 'warning' || value === 'info';
}

export type Log_Message = {type: Log_Message_Type, code: string, message: string};
export type Log_Messages = Record<string, Log_Message>;

//
// Text
//
export function sprintf(text: string, ...args: Array<string | number>): string
{
    let i = 0;
    return text.replace(/%s/g, () => args[i++].toString());
}

export function to_title_case(text: string): string
{
    var parts = text.toLowerCase().split(' ');
    for(var i = 0; i < parts.length; i++)
        parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].substring(1);
    return parts.join(' '); 
}

//
// Types.
//
export function is_array(x: any): x is Array<any>
{
    return Array.isArray(x)
}

export function is_object(x: any): x is Object
{
    return x && typeof(x) && !Array.isArray(x)
}

export function parse_JSON(text: string): any | undefined
{
    try{ return JSON.parse(text) }catch{}
}

//
// Miscellanious.
//
export function serialize_query(data: {[key: string]: string}): string
{
    let result = ''
    for(const key in data)
        result += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&'
    return result
}

export function sleep(ms: number): Promise<void>
{
    return new Promise((resolve) => setTimeout(resolve, ms))
}
