import { sanitize } from "dompurify";

import { Auth } from "../auth";
import { _t } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { Updater } from "../updater";
import { Users } from "../users";
import * as friends_menu from "../ui/friends_menu";
import { print_global_message } from "../ui/global_message";
import { Friends_Menu } from "../ui/friends_menu";

export class Users_Requests_Out_Scene extends Scene
{
    constructor(updater: Updater)
    {
        super(updater, 'users/requests/out');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
        this._init_tools_element();
    }

    async update()
    {
        await this._load_users();
        this._friends_menu.update(); // After loading the users since it uses the most recent data.
    }

    private _friends_menu!: Friends_Menu;
    private _list_element!: HTMLDivElement;
    private _nothing_found_element!: HTMLDivElement;
    private _query_element!: HTMLInputElement;
    // private _search_result_element!: HTMLDivElement;
    private _search_timeout?: NodeJS.Timeout;

    private _init_element()
    {
        this.element.className = 'page-section';
        
        /* this.element.innerHTML = `
            <h1>Send Friend Request</h1>
            <div class="search">
                <input class="full-width" name="query" type="text" placeholder="Friend ID"/>
                <div class="search-result">

                </div>
            </div>
            <hr/>`; */

        this.element.innerHTML = `
            <h1 data-lang="users/sent_requests">Sent Requests</h1>
            <div class="users-container">
            
            </div>`;

        // this._query_element = this.element.querySelector('[name="query"]') as HTMLInputElement;
        // this._query_element.addEventListener('input', this._on_search.bind(this));

        // this._search_result_element = this.element.querySelector('.search-result') as HTMLDivElement;

        this._list_element = this.element.querySelector('.users-container')!;
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = `
            <p class="text-center" data-lang="general/nothing_found">
                Nothing found.
            </p>`;
    }

    private _init_tools_element()
    {
        this.tools_element = document.createElement('div');
        this._friends_menu = new Friends_Menu(this.tools_element, this.path, this._updater);
    }

    private async _load_users()
    {
        if( !Auth.current_user ){
            this._list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }
        
        const users = await Users.search({'relation': 'friend_request_sent'});
        if( !users ){
            this._list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this._list_element.innerHTML = '';

        for(const user of users){

            const item_element = document.createElement('div');
            item_element.className = 'main-list-item user-list-item button';
            item_element.dataset.id = user.uid;
            item_element.innerHTML = `
                <div class="image-container">
                    <img src="${sanitize(Users.get_image_uri(user.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${sanitize(user.first_name)} ${sanitize(user.last_name)}</div>
                </div>`;

            item_element.addEventListener('click', async () => {
                await Scene_Manager.open('user', {'uid': user.uid});
                this._updater.run();
            });

            this._list_element.append(item_element);
        }

        if( users.length === 0 )
            this._list_element.append(this._nothing_found_element);
    }

    /* private async _on_search(_: Event)
    {
        if( this._search_timeout )
            clearTimeout(this._search_timeout);

        const value = this._query_element.value.trim().toLowerCase();
        if( value.length !== USER_UID_LENGTH ){
            this._search_result_element.innerHTML = '';
            return;
        }

        this._search_result_element.innerHTML = '<p class="text-center">Loading ...</p>';

        this._search_timeout = setTimeout(async () => {
            if( !Auth.current_user )
                return;
            
            const messages = {};

            const target_user = await Users.get(value, messages);
            if( !target_user ){
                this._search_result_element.innerHTML = '<p class="text-center">Nothing found.</p>';
                print_global_messages(messages);
                return;
            }

            this._search_result_element.innerHTML = `
                <div class="main-list-item user-list-item">
                    <div class="image-container">
                        <img src="${sanitize(Users.get_image_uri(target_user.image, 'main_list_item'))}"/>
                    </div>
                    <div class="info-container">
                        <div class="name">${sanitize(target_user.first_name)} ${sanitize(target_user.last_name)}</div>
                    </div>
                    <div class="image-container">
                        <button class="button add-button round primary">+</button>
                    </div>
                </div>`;

            const befriend_button = this._search_result_element.querySelector('.add-button')!
            befriend_button.addEventListener('click', () => { this._send_request(target_user.uid) });

        }, 1000);
    } */

    /* private async _send_request(uid: string)
    {
        if( !Auth.current_user )
            return;

        const messages = {};

        const target_user = await Users.befriend(Auth.current_user, {'uid': uid}, messages);
        if( !target_user ){
            print_global_messages(messages);
            return;
        }

        print_global_message('success', 'Your request was sent successfully.');
        
        this._search_result_element.innerHTML = '';
        this._query_element.value = '';

        this._updater.run();
    } */
}
