import { Clipboard } from '@capacitor/clipboard';
import { Share } from '@capacitor/share';
import { sanitize } from "dompurify";

import { Auth } from "../auth";
import { Scene, Scene_Manager } from "../scenes";
import { Updater } from "../updater";
import { Users }  from "../users";
import { Friends_Menu } from "../ui/friends_menu";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { _t } from '../localization';
import { HTML_Popup } from '../ui/popups/html_popup';
import { is_mobile } from '../core';

export class Users_Requests_In_Scene extends Scene
{
    list_element!: HTMLDivElement;

    constructor(updater: Updater)
    {
        super(updater, 'users/requests/in');

        this.user_state = 'active';

        this._init_element();
        this._init_nothing_found_element();
        this._init_tools_element();
    }

    async update()
    {
        if( !Auth.current_user)
            return;

        await this._load_users();
        this._friends_menu.update(); // After loading the users since it uses the most recent data.
    }

    private _friends_menu!: Friends_Menu;
    private _nothing_found_element!: HTMLDivElement

    private async _accept_friend_request(event: Event, uid: string)
    {
        event.stopPropagation();
        if( !Auth.current_user)
            return;
        
        const messages = {};
        const user = await Users.befriend(Auth.current_user, {'uid': uid}, messages);
        if( !user ){
            print_global_messages(messages);
            return;
        }

        print_global_message('success', _t('users/friend_list_updated'));
        this._updater.run();
    }

    private async _decline_friend_request(event: Event, uid: string)
    {
        event.stopPropagation();
        if( !Auth.current_user)
            return;

        const messages = {};
        const user = await Users.unfriend(Auth.current_user, {'uid': uid}, messages);
        if( !user ){
            print_global_messages(messages);
            return;
        }

        this._updater.run();
    }

    private _init_element()
    {
        this.element.className = 'page-section';
        this.element.innerHTML = `
            <h1 data-lang="users/my_friend_link">My Friend Link</h1>
            <div class="flex-equal horizontal-rounded-content" style="margin-top: 20px">
                <button class="copy" type="button" data-lang="users/copy_link">Copy link</button>
                <button class="info" style="flex-grow: 0 !important" type="button" data-lang="general/info">Info</button>
            </div>
            <hr>

            <h1 data-lang="users/received_requests">Received Requests</h1>
            <div class="users-container"></div>`;

        const copy_button = this.element.querySelector('button.copy')!
        copy_button.addEventListener('click', this._on_copy_link.bind(this));
        this.element.querySelector('button.info')!.addEventListener('click', this._on_info_link.bind(this));

        if( is_mobile() ){
            const share_button = document.createElement('button');
            share_button.addEventListener('click', this._on_share_link.bind(this));
            share_button.className = 'primary share';
            share_button.dataset.lang = 'users/share';
            share_button.textContent = 'Share';
            copy_button.parentElement!.prepend(share_button);
        }

        this.list_element = this.element.querySelector('.users-container') as HTMLDivElement;
    }

    private _init_nothing_found_element()
    {
        this._nothing_found_element = document.createElement('div');
        this._nothing_found_element.innerHTML = `
            <p class="text-center" data-lang="general/nothing_found">
                Nothing found.
            </p>`;
    }

    private _init_tools_element()
    {
        this.tools_element = document.createElement('div');
        this._friends_menu = new Friends_Menu(this.tools_element, this.path, this._updater);
    }

    private async _load_users()
    {
        if( !Auth.current_user )
            return;
        
        const users = await Users.get_received_friend_requests();
        if( !users ){
            this.list_element.innerHTML = '';
            print_global_message('error', _t('general/loading_error'));
            return;
        }

        this.list_element.innerHTML = '';

        for(const user of users){

            const item_element = document.createElement('div');
            item_element.className = 'main-list-item user-list-item button ' + user.relation;
            item_element.dataset.id = user.uid;
            item_element.innerHTML = `
                <div class="image-container">
                    <img src="${sanitize(Users.get_image_uri(user.image, 'main_list_item'))}"/>
                </div>
                <div class="info-container">
                    <div class="name">${sanitize(user.first_name)} ${sanitize(user.last_name)}</div>
                </div>
                <div class="image-container">
                    <a><img class="accept-button" src="/assets/images/accept.png"></a>
                </div>
                <div class="image-container">
                    <a><img class="decline-button" src="/assets/images/decline.png"></a>
                </div>`;

            item_element.addEventListener('click', async () => {
                await Scene_Manager.open('user', {'uid': user.uid});
                this._updater.run();
            });

            item_element.querySelector('.accept-button')!.addEventListener('click', activity => this._accept_friend_request(activity, user.uid));
            item_element.querySelector('.decline-button')!.addEventListener('click', activity => this._decline_friend_request(activity, user.uid));

            this.list_element.append(item_element);
        }

        if( users.length === 0 )
            this.list_element.append(this._nothing_found_element);
    }

    private async _on_copy_link()
    {
        if( !Auth.current_user )
            return;

        await Clipboard.write({
            string: Users.generate_friend_link(Auth.current_user)
        });

        print_global_message('success', _t('users/link_copied'));
    }

    private async _on_info_link()
    {
        const text_element = document.createElement('p');
        text_element.innerHTML = _t('users/friend_link_description');

        const popup = new HTML_Popup('friend_link_info', _t('users/my_friend_link'), text_element);
        popup.open();
    }

    private async _on_share_link()
    {
        if( !Auth.current_user )
            return;

        await Share.share({
            title: _t('users/share_message/title', sanitize(Auth.current_user.first_name)),
            text: _t('users/share_message/text'),
            url: Users.generate_friend_link(Auth.current_user),
            dialogTitle: _t('users/share_message/dialog_title'),
        });
    }
}
