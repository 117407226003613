import { Auth } from "../auth";
import * as DOM from "../ui/DOM";
import { Scene } from "../scenes";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";

export class Auth_Verify_Email_Scene extends Scene
{
    form: HTMLFormElement;

    constructor(updater: Updater)
    {
        super(updater, 'auth/verify_email');

        this.element.className = 'page-section';
        this.element.innerHTML = `
        <form>
            <p class="text-center">
                After clicking 'Send code', you'll receive an email with a code to verify your email address.
            </p>
            <p class="text-center">
                This might take a few minutes. Don't forget to check your spam.
                If you didn't receive anything, you can click 'Send code' again. The old code will be invalidated.
            </p>
            <label class="block full-width">
                Code:<br/>
                <input class="full-width" name="code" type="text" required>
            </label>
            <button class="primary full-width" name="verify" style="margin-bottom: 10px">
                Verify
            </button>
            <button class="full-width" name="resend" type="button">
                Send code
            </button>
        </form>`;

        this.form = this.element.querySelector('form')!;
        this.form.addEventListener('submit', this._on_submit.bind(this));

        this.element.querySelector("[name='verify']")!.addEventListener('click', () => { this.form.classList.add('submitted') });
        this.element.querySelector("[name='resend']")!.addEventListener('click', this._on_resend_click.bind(this));
    }

    clear_password_field()
    {
        (this.element.querySelector("[name='password']") as HTMLInputElement).value = '';
    }

    async _on_submit(event: Event)
    {
        event.preventDefault();

        const data = DOM.parse_form(this.form);
        if( !data['code'] || typeof(data['code']) !== 'string' )
            return;

        const messages = {};
        if( !await Auth.verify_email(data['code'], messages) ){
            print_global_messages(messages);
            return;
        }

        location.reload();
    }

    async _on_resend_click()
    {
        const messages = {};
        if( !await Auth.request_email_verification(messages) ){
            print_global_messages(messages);
            return;
        }

        print_global_message('success', 'Verification email sent.');
    }
}
