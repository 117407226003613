import { sanitize } from "dompurify";

import { Auth } from "../auth";
import * as DOM from "../ui/DOM";
import { Activities } from "../activities";
import { Locale } from "../localization";
import { Scene, Scene_Manager } from "../scenes";
import { Country_Input } from "../ui/country_input";
import { print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";
import { _t } from "../localization";
import { Log_Messages } from "../core";

export class Activities_Create_Scene extends Scene
{
    form: HTMLFormElement;

    constructor(updater: Updater)
    {
        super(updater, 'activities/create');

        this.user_state = 'active';

        this.element.className = 'page-section';
        this.element.innerHTML = `
        <form>
            <h1 data-lang="activities/new_activity">New Activity</h1>

            <label class="block full-width">
                <span data-lang="activities/name">Name:</span><br/>
                <input class="full-width" name="name" type="text" required>
            </label>

            Description:
            <div data-lang="activities/description_explanation">
                <p>What do people need to think of before they join your activity? Try to be thorough. Some example are ...</p>
                <ul>
                    <li>... tools and outfit.</li>
                    <li>... entree fee to party.</li>
                </ul>
            </div>
            <label class="block full-width">
                <textarea class="full-width" name="description" type="text" required></textarea>
            </label>

            <hr/>

            <label class="block full-width">
                <span data-lang="activities/date_and_time">Date and Time:</span><br/>
                <input class="full-width" name="datetime" type="datetime-local" required>
            </label>
            <label class="block full-width">
                <span data-lang="activities/deadline">Signup Deadline:</span><br/>
                <input class="full-width" name="deadline" type="datetime-local" required>
            </label>
            <label class="gender-section block full-width"></label>
            <hr/>

            <label class="block full-width">
                <span data-lang="activities/min_guests">Minimum amount of guests:</span><br/>
                <input class="full-width" name="min_guests" type="number" min="1" required>
            </label>
            <label class="block full-width">
                <span data-lang="activities/max_guests">Maximum amount of guest:</span><br/>
                <input class="full-width" name="max_guests" type="number" min="1" required>
            </label>
            <label class="block full-width">
                <span data-lang="activities/max_invitees">Maximum amount of friends a guest can take with them:</span><br/>
                <input class="full-width" name="max_invitees" type="number" min="0" required>
            </label>

            <hr/>

            <label class="block full-width">
                <input name="free" type="checkbox">
                <span data-lang="activities/free_question">Free of charge for guests?</span>
            </label>

            <hr/>

            <label class="block full-width">
                <span data-lang="activities/address">Address:</span><br/>
                <input class="full-width" name="address" type="text" required>
            </label>
            <label class="block full-width">
                <span data-lang="activities/city">City:</span><br/>
                <input class="full-width" name="city" type="text" required>
            </label>
            <label class="block full-width country-section">
                <span data-lang="activities/country">Country:</span>
            </label>

            <hr/>

            <label>
                <span data-lang="activities/image">Image (enter up to 3 keywords):</span><br/>
                <input class="full-width" name="image-search" placeholder="&#8981;" style="margin-bottom: 20px" type="search"/>
            </label>
            <div class="activity-image-picker"></div>

            <hr/>

            <div data-lang="activities/creation_bottom_info">
                <ul>
                    <li>After publishing, the activity can't be edited anymore.</li>
                    <li>If you remove an activity before there are any guests, it won't be counted as a cancelation and won't be visible to others.</li>
                </ul>
            </div>

            <hr/>

            <div class="flex-equal horizontal-rounded-content" style="margin-top: 40px">
                <button class="primary" name="submit" data-lang="activities/launch">
                    Publish
                </button>
                <button name="cancel" type="button" data-lang="general/cancel">
                    Cancel
                </button>
            </div>
        </form>`;

        this.form = this.element.querySelector('form')!;
        this.form.addEventListener('submit', this._on_submit.bind(this));

        this._image_search_input = this.element.querySelector("[name='image-search']")!;
        this._image_search_input.addEventListener('input', this._load_images.bind(this));

        this.element.querySelector("[name='submit']")!.addEventListener('click', () => { this.form.classList.add('submitted') });
        this.element.querySelector("[name='cancel']")!.addEventListener('click', () => {
            Scene_Manager.go_back()
            this._updater.run()
        });

        this._country_element = new Country_Input({ required: true });
        this._country_element.element.classList.add('full-width');
        this.element.querySelector('.country-section')!.append(this._country_element.element);
    }

    async open()
    {
        await super.open();

        if( this._is_first_open ){
            this.reset_form();
            this._is_first_open = false;
        }

        this._load_images();
    }

    reset_form()
    {
        if( !Auth.current_user )
            return false;

        const user = Auth.current_user;
        DOM.set_form_values(this.form, {
            'address': user.address,
            'city': user.city,
            'country': user.country,
        });

        this.form.classList.remove('submitted');

        const gender_section_element = this.form.querySelector('.gender-section')!;
        if( Auth.current_user.gender === 'male' )
            gender_section_element.innerHTML = `<input name="gender" type="checkbox" value="male"><span data-lang="activities/men_only">Men only</span>`;
        else if( Auth.current_user.gender === 'female' )
            gender_section_element.innerHTML = `<input name="gender" type="checkbox" value="female"><span data-lang="activities/women_only">Women only</span>`;
    }

    private _country_element: Country_Input;
    private _is_first_open = true;
    private _image_search_input!: HTMLInputElement;

    private _load_images()
    {
        const query = this._image_search_input.value;

        const image_container = this.element.querySelector('.activity-image-picker')!;
        image_container.innerHTML = '<p class="text-center">' + _t('general/loading') + '</p>';

        setTimeout(async () => {
            if( this._image_search_input.value != query )
                return;

            // Use `full-width` because the parent element is flex.
            const messages: Log_Messages = {};
            const images = await Activities.get_images(query, messages);
            if( !images ){
                const error = messages['too_many_keywords'].message || _t('general/image_loading_error');
                image_container.innerHTML = '<p class="text-center full-width error">' + error + '</p>';
                print_global_messages(messages);
                return;
            }else if( !images.length  ){
                image_container.innerHTML = '<p class="text-center full-width">' + _t('general/no_image_found') + '</p>';
                return;
            }

            image_container.innerHTML = '';

            for(const image of images){
                image_container.innerHTML += `
                    <label class="entry">
                        <input name="image" type="radio" value="${sanitize(image['uid'])}" style="display: none"/>
                        <span class="image-cell"><img src="${sanitize(image['url'])}"/></span>
                    </label>`;
            }
        }, 1000);
    }

    private async _on_submit(event: Event)
    {
        event.preventDefault();

        if( !Auth.current_user )
            return false;

        const form_data = DOM.parse_form(this.form);
        form_data['paid'] = form_data['free'] ? '' : '1';

        const messages = {};
        const activity = await Activities.create(form_data, messages);
        print_global_messages(messages);
        if( !activity )
            return;

        await Scene_Manager.open('activities');

        this.reset_form();

        this._updater.run();
    }
}
