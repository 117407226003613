export const data: {[key: string]: string} = {

    "activities/address": "Adres:",
    "activities/all": "alles",
    "activities/at_date": "Op:",
    "activities/cancel_confirm": `Wil je deze activiteit permanent annuleren voor jou en alle gasten? Laat iedereen weten waarom je annuleert. Gebrek aan communicatie kan leiden tot gasten die je account rapporteren en daarom een schorsing.`,
    "activities/canceled": 'Geannuleerd',
    "activities/chat_not_opened": "Kan de chat niet automatisch openen. Bekijk je chats overzicht.",
    "activities/creation_bottom_info": `
        <ul>
            <li>Activiteiten kunnen niet aangepast worden na het lanceren.</li>
            <li>Vermijd het annuleren wanneer deelnemers zich al hebben opgegeven. Indien het niet anders kan, laat het ze weten via de chat.</li>
        </ul>`,
    "activities/city": "Stad:",
    "activities/country": "Land:",
    "activities/date": "Datum:",
    "activities/date_and_time": "Datum van Activiteit:",
    "activities/deadline": "Uiterste Inschrijfdatum:",
    "activities/decline": "Afwijzen",
    "activities/description_explanation": `
        <p>Waar moet je rekening mee houden voordat je je opgeeft voor je activiteit? Wees specifiek. Sommige voorbeelden zijn ...</p>
        <ul>
            <li>... benodigdheden en outfit.</li>
            <li>... kosten voor een feest.</li>
        </ul>`,
    "activities/expired": "Verlopen",
    "activities/free": "Gratis",
    "activities/free_question": "Gratis voor deelnemers?",
    "activities/friends_more": "%s meer",
    "activities/guests": "Deelnemers:",
    "activities/guest_count": "%s tot %s.",
    "activities/history": "geschiedenis",
    "activities/host": "Organisator:",
    "activities/image": "Afbeelding (gebruik maximaal 3 kernwoorden):",
    "activities/invited": "Uitgenodigd",
    "activities/join": "Deelnemen",
    "activities/join_confirm": "Deelnemen aan deze activiteit?",
    "activities/label/multiple_friends": "+%s Vrienden",
    "activities/label/one_friend": "+1 Vriend",
    "activities/launch": "Lanceren",
    "activities/leave": "Afmelden",
    "activities/left": "Afgemeld",
    "activities/location": "Locatie:",
    "activities/men_only" : "Alleen mannen",
    "activities/max_guests" : "Maximum aantal deelnemers:",
    "activities/max_invitees" : "Maximum aantal vrienden die een deelnemer mag meenemen:",
    "activities/min_guests" : "Minimum aantal deelnemers:",
    "activities/mine": "mijn",
    "activities/name": "Naam:",
    "activities/new_activity": "Nieuwe Activiteit",
    "activities/no_discovery": "Geen nieuwe activiteiten gevonden.",
    "activities/no_upcomming": "Geen aankomende activiteiten.",
    "activities/no_upcomming_create": "Organiseer een activiteit",
    "activities/no_upcomming_discover": "Ontdek activiteiten om aan deel te nemen",
    "activities/note/invite": "Nodig een vriend uit",
    "activities/number_of_guests": "Aantal deelnemers:",
    "activities/people_you_know": "Mensen die je kent",
    "activities/saved": "Opgeslagen",
    "activities/spots/multiple_spots_left": "Nog %s plekken beschikbaar",
    "activities/spots/no_spot_left": "Volgeboekt",
    "activities/spots/one_spot_left": "Nog 1 plek beschikbaar",
    "activities/suspended_explanation": "Deze activiteit heeft de voorwaarden overtreden en is afgelast.",
    "activities/title": "Activiteiten",
    "activities/upcomming": "aankomend",
    "activities/women_only" : "Alleen vrouwen",
    "activities/x_more_guests": "Nog %s deelnemer(s) nodig",

    "auth/create": "Aanmaken",
    "auth/create_new_account": "Maak nieuw account aan",
    "auth/download": "Download de App",
    "auth/login": "Inloggen",
    "auth/login_with_facebook": "Login met Facebook",
    "auth/login_with_google": "Login met Google",
    "auth/log_out": "Uitloggen",
    "auth/or": "of",
    "auth/password": "Wachtwoord:",
    "auth/remember_me": "Onthoud mij",
    "auth/use_existing_account": "Inloggen met bestaand account",

    "chats/active_members": "Actieve Leden",
    "chats/activity": "Activiteit",
    "chats/admin_assign_confirm": "Weet je zeker dat je dit lid tot beheerder wilt maken? Je kunt deze chat dan niet meer beheren.",
    "chats/admin_leave_error": "Stel iemand aan als beheerder voordat je de chat verlaat.",
    "chats/chat_image": "Chat Afbeelding",
    "chats/chat_not_found": "Chat niet gevonden.",
    "chats/decline_confirm": "Weet je zeker dat je dit verzoek wilt afwijzen?",
    "chats/empty_name_error": "Vul een naam in.",
    "chats/former_members": "Voormalige Leden",
    "chats/group_name": "Groepsnaam:",
    "chats/leave": "Verlaat chat",
    "chats/leave_confirm": "Weet je zeker dat je deze chat wilt verlaten?",
    "chats/make_admin": "Maak tot admin",
    "chats/message_removed_status": "(verwijderd)",
    "chats/message_suspended_status": "(geschorst)",
    "chats/messages": "Berichten",
    "chats/new_chat": "Nieuwe Chat",
    "chats/new_messages": "Nieuwe Berichten",
    "chats/no_former_members": "Geen voormalige leden.",
    "chats/no_image_selected": "Selecteer eerst een afbeelding.",
    "chats/no_name": "(naamloos)",
    "chats/no_pending_members": "Niemand in afwachting.",
    "chats/not_a_group_error": "Dit is geen groepschat.",
    "chats/not_a_member_error": "Je bent geen lid. Alleen vrienden kunnen je toevoegen aan de chat.",
    "chats/pending_members": "Verzoeken",
    "chats/remove_member_confirm": "Weet je zeker dat je dit lid wilt verwijderen?",
    "chats/remove_message_confirm": "Weet je zeker dat je dit bericht wilt verwijderen?",
    "chats/reply": "Reageren",
    "chats/user_not_found": "Gebruiker niet gevonden.",
    "chats/write_placeholder": "Schrijf een bericht...",

    "general/add": "Toevoegen",
    "general/cancel": "Annuleren",
    "general/confirm": "Bevestigen",
    "general/image_loading_error": "Er heeft zich een probleem voorgedaan bij het laden van de afbeelding.",
    "general/info": "Info",
    "general/invite": "Uitnodigen",
    "general/loading": "Laden...",
    "general/loading_error": "We konden je gegevens niet laden. Probeer het opnieuw.",
    "general/more_info": "meer info",
    "general/no_connection": "Kan geen verbinding maken. Probeer het opnieuw.",
    "general/no_image_found": "Geen afbeelding gevonden.",
    "general/not_connected": "Geen verbinding. Probeer het opnieuw",
    "general/nothing_found": "Niets gevonden.",
    "general/options": "Opties",
    "general/or": "of",
    "general/page_not_found": "Pagina niet gevonden.",
    "general/premium_only": "Deze functie is alleen beschikbaar voor premium gebruikers.",
    "general/remove": "Verwijderen",
    "general/save": "Opslaan",
    "general/servers_down": "Onze servers zijn momenteel niet beschikbaar. Probeer het later opnieuw.",
    "general/suspended": "Geschorst",
    "general/unknown": "onbekend",
    "general/you": "jij",

    "media/invalid_image_type": "De geselecteerde afbeelding is geen JPEG of PNG.",
    "media/load_error": "Het bestand kon niet worden geladen. Probeer het opnieuw.",
    "media/select_other_image": "Selecteer andere afbeelding",

    "network/no_connection": "Geen netwerkverbinding.",

    "notifications": "Meldingen",

    "push/error": "Push notification error.",
    "push/not_granted": "Pushmeldingen zijn niet ingeschakeld.",

    "users/accept_friendship": "Accepteer Vriendschap",
    "users/accept_declined_friendship": "Accepteer Afgewezen Vriendschap",
    "users/address": "Adres:",
    "users/address_info": "Het onderstaande adres kan gebruikt worden om activiteiten in de buurt te vinden. Je kunt deze later bijwerken om activiteiten in specifieke locaties te ontdekken, bijvoorbeeld wanneer je op vakantie bent.",
    "users/block": "Blokkeren",
    "users/block_confirm": "Weet je zeker dat je deze gebruiker wilt blokkeren? Privéberichten worden geblokkeerd en jullie kunnen niet meer deelnemen aan activiteiten georganiseerd door de ander.",
    "users/blocked": "Geblokkeerd",
    "users/blocked_users": "Geblokkeerde Gebruikers",
    "users/city": "Woonplaats:",
    "users/copy_link": "Kopieer Link",
    "users/could_not_load_user": "Kan deze gebruiker niet laden.",
    "users/country": "Land:",
    "users/date_of_birth": "Geboortedatum:",
    "users/decline_friendship": "Wijs Vriendschap Af",
    "users/edit_preferences": "Bewerk Voorkeuren",
    "users/email": "E-mail:",
    "users/first_name": "Voornaam:",
    "users/female": "Vrouw",
    "users/friend_link_description": `
        Deel deze link met anderen, zodat ze je een vriendschapsverzoek kunnen sturen.
        Dit werkt ook als ze nog geen Wuddy's account hebben.
        Je vindt deze verzoeken dan hier op deze pagina terug, waar je ze kunt accepteren of afwijzen.`,
    "users/friend_list_updated": "Je vriendenlijst is bijgewerkt.",
    "users/friend_request_sent": "Vriendschapsverzoek verzonden",
    "users/friends": "Vrienden",
    "users/gender": "Geslacht:",
    "users/gender_preference": "Geslachtsvoorkeur:",
    "users/gender_preference/all": "alles",
    "users/gender_preference/same": "zelfde",
    "users/invite_existing": "Nodig een bestaande vriend uit",
    "users/language": "Taal:",
    "users/last_name": "Achternaam:",
    "users/link_copied": "Link is gekopieerd.",
    "users/male": "Man",
    "users/max_age_diff": "Maximaal leeftijdsverschil met organisator:",
    "users/meet_new": "Ontmoet nieuwe mensen",
    "users/my_friend_link": "Mijn Vriendenlink",
    "users/no_image_selected": "Selecteer eerst een afbeelding.",
    "users/phone": "Telefoon:",
    "users/personal_info": "Persoonlijke Info",
    "users/preferences": "Voorkeuren",
    "users/profile": "Profiel",
    "users/profile_picture": "Profielfoto",
    "users/profile_picture/description": `
        Zorg ervoor dat je gezicht goed zichtbaar is.
        Deze afbeelding is voor iedereen zichtbaar en wordt voornamelijk gebruikt zodat deelnemers aan activiteiten elkaar gemakkelijk kunnen herkennen.`,
    "users/received_requests": "Ontvangen",
    "users/search_radius": "Zoekafstand (km):",
    "users/send_friend_request": "Verzend Vriendschapsverzoek",
    "users/sent_requests": "Verzonden",
    "users/share": "Delen",
    "users/share_message/dialog_title": "Deel Wuddy's vriendenlink.",
    "users/share_message/text": "Je kunt deze link gebruiken om mij een Wuddy's vriendschapsverzoek te sturen.",
    "users/share_message/title": "%s heeft je een Wuddy's vriendschapsverzoek gestuurd.",
    "users/unblock": "Deblokkeren",
    "users/unfriend": "Ontvrienden",
    "users/unfriend_confirm": "Weet je zeker dat je deze persoon wilt ontvrienden?",
    "users/verification_error": "We kunnen je account niet verifiëren. Neem contact op.",
};
