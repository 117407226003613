import * as io from "socket.io-client";

import { API, API_Data, parse_API_messages } from "./api";
import { Auth } from "./auth";
import * as core from "./core";
import { _t } from "./localization";
import { print_global_message, print_global_messages } from "./ui/global_message";
import { User_Summary_Data, Users } from "./users";
import { Network } from "./network";

const MAX_MESSAGES_PER_REQUEST = 20; // When updating, don't forget to update the server variable as well.
export type Incomming_Message_Type = 'new' | 'old';
export type New_Message_Position = 'top' | 'bottom' | 'middle';

export class Message
{
    id = 0;
    chat = '';
    user = '';
    content = '';
    reply_to?: Message;
    type = '';
    status = '';
    created?: Date;
}

class Message_Cache
{
    is_first_message_loaded = false;
    start_datetime?: Date;
    end_datetime?: Date;

    messages = new Array<Message>;
    message_map = new Map<number, Message>;
}

export class Chat
{
    uid = '';
    activity = '';
    name = '';
    type = '';
    image = '';
    last_message_datetime?: Date;
    created?: Date;
    updated?: Date;
    group_size = 0;

    members = new Array<User_Summary_Data>;
    member_map = new Map<string, User_Summary_Data>;
    member_last_read_message = 0;
    member_role = '';
    member_status = '';
    member_unread_count = 0;

    message_cache = new Message_Cache;
}

export class Messages
{
    static async get_by_chat(chat: string, data: API_Data, log: core.Log_Messages = {}): Promise<Message[] | undefined>
    {
        const response = await API.GET(`/chat/${encodeURIComponent(chat)}/messages`, data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        const messages = new Array<Message>;
        for(const idx in result.data.messages){
            const message = this.parse(result.data.messages[idx]);
            if( message )
                messages.push(message);
        }

        return messages;
    }

    static parse(data: any, message: Message | false = false): Message | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !message )
            message = new Message

        message.id = data.id || 0
        message.chat = data.chat;
        message.user = data.user;
        message.content = data.content || '';
        message.reply_to = data.reply_to ? this.parse(data.reply_to) : undefined;
        message.type = data.type || '';
        message.status = data.status || '';
        message.created = core.UTC(data.created);
        
        return message;
    }
}

export class Chats
{
    static async create_group_chat(name: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const response = await API.POST('/chat/group', {'name': name});
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return Chats.parse(result.data.chat);
    }

    static async create_private_chat(user: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const response = await API.POST('/chat/private', {'user': user});
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return Chats.parse(result.data.chat);
    }

    static async get(uid: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const response = await API.GET('/chat/' + encodeURIComponent(uid));
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return this.parse(result.data.chat);
    }

    static get_image_url(image?: string, context='')
    {
        if( image )
            return image + '?context=' + encodeURIComponent(context);
        else
            return '/assets/images/default-chat-image.png';
    }

    static async get_all(data: API_Data, log: core.Log_Messages = {}): Promise<Map<string, Chat> | undefined>
    {
        const response = await API.GET('/chats', data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        const chats = new Map<string, Chat>();
        for(const idx in result.data.chats){
            const chat = this.parse(result.data.chats[idx]);
            if( chat )
                chats.set(chat.uid, chat);
        }

        return chats;
    }

    static parse(data: any, chat: Chat | false = false): Chat | undefined
    {
        if( !core.is_object(data) )
            return;

        if( !chat )
            chat = new Chat

        chat.uid = data.uid || '';
        chat.activity = data.activity;
        chat.name = data.name || '';
        chat.type = data.type || '';
        chat.image = data.image || '';
        chat.last_message_datetime = core.UTC(data.last_message_datetime);
        chat.created = core.UTC(data.created);
        chat.updated = core.UTC(data.updated);
        chat.group_size = data.group_size || 0;

        chat.members = Users.parse_summary_array(data.members);
        chat.members.forEach(member => chat.member_map.set(member.uid, member));

        chat.member_last_read_message = data.member_last_read_message || 0;
        chat.member_role = data.member_role || '';
        chat.member_status = data.member_status || '';
        chat.member_unread_count = data.member_unread_count || 0;

        return chat;
    }
}

export class Chat_Manager
{
    static add_member(chat: Chat, user_uid: string): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/add_member', {'chat': chat.uid, 'user': user_uid});

        return true;
    }

    static async create_group_chat(name: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const chat = await Chats.create_group_chat(name, log);
        if( chat ){
            this._cache.set(chat.uid, chat);
            this._sort_chats();
        }

        return chat;
    }

    static async create_private_chat(user: string, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const chat = await Chats.create_private_chat(user, log);
        if( chat ){
            this._cache.set(chat.uid, chat);
            this._sort_chats();
        }

        return chat;
    }

    static async get(uid: string): Promise<Chat | undefined>
    {
        let chat = Chat_Manager._cache.get(uid);
        if( chat )
            return chat;

        chat = await Chats.get(uid);
        if( chat )
            Chat_Manager._cache.set(uid, chat);

        return chat;
    }

    static get_all(): Map<string, Chat> | undefined
    {
        return Chat_Manager._cache;
    }

    /**
     * @returns TRUE on success, FALSE on socket failure, for example if the socket is still trying to connect.
     */
    static get_messages_by_chat(chat: Chat, first_message?: Message, before_message?: Message, check_cache: 'use_cache' | 'ignore_cache' = 'use_cache'): boolean
    {
        if( check_cache ){
            const cache = this._get_message_cache(chat, first_message, before_message);
            if( cache ){
                // Use timeout for consistency if there was no cache.
                // Inconsistency might up the loading of the UI.
                setTimeout(() => {
                    dispatchEvent(
                        new CustomEvent('receive_messages', { detail: {messages: cache, position: 'top'} })
                    );
                }, 100);
                return true;
            }
        }

        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        const data: Record<string, unknown> = { 'chat': chat.uid, 'pos': 'bottom' };

        if( first_message )
            data['first'] = first_message.id;
        if( before_message )
            data['before'] = before_message.id;
            
        this._socket.emit('chats/get_messages_by_chat', data);

        return true;
    }

    static get_private_chat_by_user(user: string): Chat | undefined
    {
        for(const chat of this._cache.values()){
            if( chat.type === 'private'
             && chat.members.length === 2
             && (chat.members[0].uid === user || chat.members[1].uid === user) )
                return chat;
        }

        return;
    }
    
    // Checks if recent messages have been missed for example when the user has been offline.
    static get_recent_messages_by_chat(chat: Chat): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }

        const chat_cache = this._cache.get(chat.uid);
        if( !chat_cache )
            return false;

        // Check if messages have been loaded before.
        const message_count = chat_cache.message_cache.messages.length;
        if( message_count === 0 && !chat_cache.message_cache.is_first_message_loaded )
            return true;
        
        const data: Record<string, unknown> = { 'chat': chat.uid, 'pos': 'top' };
        const messages = chat_cache.message_cache.messages
        data['after'] = messages[messages.length - 1].id;
        
        this._socket.emit('chats/get_messages_by_chat', data);

        return true;
    }

    static async init(socket: io.Socket): Promise<void>
    {
        this._socket = socket;

        addEventListener('network_connection', async () => {
            if( !Network.is_connected() )
                return;

            await this._load_chats(this._last_chat_updated_datetime);
            this._last_chat_updated_datetime = core.UTC();
            
            dispatchEvent(new CustomEvent('update_chats'));
        });

        socket.on('chats/get_messages_by_chat', this._on_get_messages_by_chat.bind(this));
        socket.on('chats/receive_message', data => this._on_process_message(data, 'new') );
        socket.on('chats/remove_message', data => this._on_process_message(data, 'old') );
        socket.on('chats/update_chat', this._on_update_chat.bind(this));

        await this._load_chats();
    }

    static remove_member(chat: Chat, user_uid: string): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/remove_member', {'chat': chat.uid, 'user': user_uid});

        return true;
    }

    static remove_message(message: Message): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/remove_message', {'message': message.id});

        return true;
    }

    static send_message(chat: Chat, content: string, reply_to?: number): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/send_message', {'chat': chat.uid, 'content': content, 'reply_to': reply_to});

        return true;
    }

    static update_admin(chat: string, user: string): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/update_admin', {'chat': chat, 'user': user});

        return true;
    }

    static update_chat(chat: string, args: {}): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/update_chat', {'chat': chat, ...args});

        return true;
    }

    static async update_image(chat: Chat, image: File, log: core.Log_Messages = {}): Promise<Chat | undefined>
    {
        const form_data = new FormData;
        form_data.append('image', image);

        const response = await API.POST('/chat/' + encodeURIComponent(chat.uid) + '/image', form_data);
        if( !response )
            return;

        const result = await response.json();
        parse_API_messages(log, result);
        if( response.status !== 200 )    
            return;

        return Chats.parse(result.data.chat, chat);
    }

    static update_last_read_message(message_id: number): boolean
    {
        if( !this._socket || !this._socket.connected ){
            print_global_message('error', _t('general/not_connected'));
            return false;
        }
        
        this._socket.emit('chats/update_last_read_message', {'message': message_id});

        return true;
    }

    private static _cache = new Map<string, Chat>;
    private static _last_chat_updated_datetime?: Date;
    private static _socket?: io.Socket;

    private static _add_message_to_cache(message: Message): boolean
    {
        const chat_cache = this._cache.get(message.chat);
        if( !message.chat || !chat_cache )
            return false;

        const message_cache_data = chat_cache.message_cache;

        if( !message_cache_data.start_datetime || message.created && message.created < message_cache_data.start_datetime )
            message_cache_data.start_datetime = message.created;

        if( !message_cache_data.end_datetime || message.created && message.created > message_cache_data.end_datetime )
            message_cache_data.end_datetime = message.created;

        if( message.created && (!chat_cache.last_message_datetime || message.created > chat_cache.last_message_datetime) )
            chat_cache.last_message_datetime = message.created;

        if( !message_cache_data.message_map.has(message.id) )
            message_cache_data.messages.push(message);
        message_cache_data.message_map.set(message.id, message);

        return true;
    }

    private static _get_message_cache(chat: Chat, first_message?: Message, before_message?: Message): Message[] | undefined
    {
        const chat_cache = this._cache.get(chat.uid);
        if( !chat_cache )
            return;

        let messages = new Array<Message>;
        const message_cache_data = chat_cache.message_cache;

        if( first_message || before_message ){
            for(let i = message_cache_data.messages.length - 1; i >= 0; i--){

                const message = message_cache_data.messages[i];

                if( first_message && message.id < first_message.id )
                    continue;

                if( before_message && message.id >= before_message.id )
                    continue;
                
                messages.push(message);

                // If only one limit is set, we can break early.
                if( (typeof first_message !== typeof before_message) && messages.length >= MAX_MESSAGES_PER_REQUEST )
                    break;
            }

            messages.reverse();
        }else{
            messages = message_cache_data.messages.slice(-MAX_MESSAGES_PER_REQUEST, message_cache_data.messages.length);
        }

        if( message_cache_data.is_first_message_loaded || messages.length > 0 )
            return messages;
        
        return;
    }

    private static async _load_chats(updated_after?: Date): Promise<void>
    {
        if( !Auth.current_user )
            return;

        const logs = {};
        
        const date_string = updated_after ? core.serialize_datetime(updated_after) : '';

        const chats = await Chats.get_all({'after': date_string}, logs);
        if( !chats ){
            print_global_messages(logs);
            return;
        }

        for(const chat of chats.values()){
            const chat_cache = this._cache.get(chat.uid);
            if( chat_cache )
                chat.message_cache = chat_cache.message_cache;

            if( chat.updated && (!this._last_chat_updated_datetime || chat.updated > this._last_chat_updated_datetime) )
                this._last_chat_updated_datetime = chat.updated;

            this._cache.set(chat.uid, chat);
        }
    }

    private static _on_get_messages_by_chat(data: any)
    {
        if( typeof data !== 'object' )
            return;

        const chat_uid = data.chat;
        const chat_cache = this._cache.get(chat_uid);
        if( typeof chat_uid !== 'string' || !chat_cache )
            return;

        const new_message_data = data['messages'];
        if( !Array.isArray(new_message_data) )
            return;

        const message_cache_data = chat_cache.message_cache;

        // 'bottom' means the first messages were loaded.
        if( data['pos'] === 'bottom' && new_message_data.length === 0 ){
            message_cache_data.is_first_message_loaded = true;
            return;
        }
        
        const new_messages = new Array<Message>;
        for(const message_data of new_message_data){

            const message = Messages.parse(message_data);
            if( !message )
                continue;

            if( !this._add_message_to_cache(message) )
                continue;

            new_messages.push(message);
        }

        this._sort_messages(message_cache_data.messages);

        // These are the most recent messages. We need all of them.
        if( data['pos'] === 'top' && new_messages.length > 0 ){
            this.get_recent_messages_by_chat(chat_cache);
            console.log('get_recent_messages_by_chat');
        }

        dispatchEvent(
            new CustomEvent('receive_messages', { detail: {messages: new_messages, position: 'top'} })
        );
    }

    private static _on_process_message(data: any, incomming_type: Incomming_Message_Type)
    {
        if( !Auth.current_user )
            return;
        
        if( typeof data !== 'object' )
            return;
        
        const new_message_data = data['message'];
        if( !new_message_data )
            return;

        const message = Messages.parse(new_message_data);
        if( !message )
            return;

        if( !this._add_message_to_cache(message) )
            return;

        const chat_cache = this._cache.get(message.chat);
        if( !chat_cache )
            return;

        if( incomming_type === 'new' && message.user !== Auth.current_user.uid ){
            chat_cache.member_unread_count++;
            this._sort_chats();
        }

        const message_cache_data = chat_cache.message_cache;
        this._sort_messages(message_cache_data.messages);

        dispatchEvent(
            new CustomEvent('receive_messages', { detail: {
                messages: [message],
                position: incomming_type === 'new' ? 'bottom' : 'middle'
            } })
        );
    }

    private static _on_update_chat(data: any)
    {
        if( typeof data !== 'object' )
            return;
        
        const chat_data = data['chat'];
        if( !chat_data )
            return;

        const chat = Chats.parse(chat_data);
        if( !chat )
            return;

        const cache_data = this._cache.get(chat.uid);
        if( cache_data ){
            // Messages might be missing that are between other messages. For simplicity, we just reload the page.
            if( !(cache_data.member_status !== 'active' && chat.member_status === 'active') )
                chat.message_cache = cache_data.message_cache;
        }

        this._cache.set(chat.uid, chat);

        this._sort_chats();

        dispatchEvent(new CustomEvent('update_chats'));
    }

    private static _sort_chats()
    {
        this._cache = new Map([...this._cache.entries()].sort((a, b) => {
            if( !b[1].last_message_datetime )
                return -1;

            if( !a[1].last_message_datetime )
                return 1;

            return b[1].last_message_datetime!.getTime() - a[1].last_message_datetime!.getTime()
        }));
    }

    private static _sort_messages(messages: Message[]): Message[]
    {
        return messages.sort((a, b) => a.id - b.id);
    }
}
