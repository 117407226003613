import * as Capacitor									from '@capacitor/app';
import { PushNotifications }							from '@capacitor/push-notifications';
import { ScreenOrientation }							from '@capacitor/screen-orientation';

import * as io											from "socket.io-client";

import { API, parse_API_messages }						from "./api";
import { Auth }    										from "./auth";
import { Chat_Manager }									from "./chats";
import { Locale, _t }									from "./localization";
import { Scene_Manager }    							from "./scenes";
import { Auth_Login_Scene }								from "./scenes/auth_login_scene";
import { Auth_Scene }									from "./scenes/auth_scene";
import { Auth_Signup_Scene } 							from "./scenes/auth_signup_scene";
import { Auth_Verify_Email_Scene }						from "./scenes/auth_verify_email_scene";
import { Activities_Create_Scene }						from "./scenes/activities_create_scene";
import { Activities_Discover_Scene }					from "./scenes/activities_discover_scene";
import { Activities_Info_Scene }						from "./scenes/activities_info_scene";
import { Activities_Scene }								from "./scenes/activities_scene";
import { Chat_Scene }									from "./scenes/chat_scene";
import { Chat_Image_Scene }								from "./scenes/chat_image_scene";
import { Chat_Info_Scene }								from "./scenes/chat_info_scene";
import { Chats_Scene }									from "./scenes/chats_scene";
import { Network }										from './network';
import { Not_Found_Scene }								from "./scenes/not_found_scene";
import { Notifications }								from './notifications';
import { Notifications_Scene }							from "./scenes/notifications_scene";
import { Profile_Personal_Info_Scene }					from "./scenes/profile_personal_info_scene";
import { Profile_Preferences_Scene } 					from "./scenes/profile_preferences_scene";
import { Profile_Picture_Scene }						from "./scenes/profile_picture_scene";
import { Profile_Scene }								from "./scenes/profile_scene";
import { Users_Blocked_Scene }							from "./scenes/users_blocked_scene";
import { Users_Friends_Scene }							from "./scenes/users_friends_scene";
import { Users_Info_Scene }								from "./scenes/users_info_scene";
import { Users_Requests_In_Scene }						from "./scenes/users_requests_in_scene";
import { Users_Requests_Out_Scene }						from "./scenes/users_requests_out_scene";
import { print_global_message, print_global_messages }	from "./ui/global_message";
import { Nav_Menu }       								from "./ui/nav_menu";
import { Updater }										from "./updater";
import { User }											from "./users";

export class App
{
	async run()
	{
		this._init_screen();

		this._updater = new Updater(this.update.bind(this));

		Locale.init();

		document.getElementById('page-body-content')!.innerHTML = '<div class="center-abs text-center">' + _t('general/loading') + '</div>';
		
		this._nav_menu = new Nav_Menu(this._updater);

		this._init_scenes();
		await this._init_auth();
		this._init_push_notifications();
		
		// Calls update, so must be initialized after _nav_menu.
		// Sets scene, so must be  after _init_auth to avoid redirecting for authentication.
		this._init_deeplinks();

		if( Auth.current_user && Auth.current_user.status === 'active' ){
			this._init_socket();
		}else{
			await Scene_Manager.open_url();
			this._updater.run();
		}

		this._init_network();

		Capacitor.App.addListener('resume', () => {
			this._updater.run();
		});
	}

	async update()
	{
		await Scene_Manager.update();

		await this._nav_menu.update();
		this._update_bottom_menu();
		Locale.update();
	}
	
	private _nav_menu!: Nav_Menu;
	private _socket?: io.Socket;
	private _updater!: Updater;

	private async _init_auth(): Promise<User | undefined>
	{
		Auth.init();
		if( !Auth.token )
			return;

		const messages = {};

		const user = await Auth.resume_session(messages);
		if( !user ){
			print_global_messages(messages);
			return;
		}

		return user;
	}

	private _init_deeplinks()
	{
		Capacitor.App.addListener('appUrlOpen', async (event: Capacitor.URLOpenListenerEvent) => {
			
			// If we login we need to remember the redirect.
			const old_query = new URLSearchParams(location.search);
			if( old_query.has('redirect') )
				event.url += (event.url.includes('?') ? '&' : '?') + 'redirect=' + old_query.get('redirect');

			await Scene_Manager.open_url(event.url);
			this._updater.run();
		});
	}

	private async _init_push_notifications()
	{
		try{
			let status = await PushNotifications.checkPermissions();
			if( status.receive === 'prompt' || status.receive === 'prompt-with-rationale' )
				status = await PushNotifications.requestPermissions();
			
			if( status.receive !== 'granted' ){
				print_global_message('error', _t('push/not_granted'));
				return;
			}

			await PushNotifications.addListener('registration', async token => {
				await Auth.register_push_notifications(token.value);
			});
			
			await PushNotifications.addListener('registrationError', err => {
				print_global_message('error', _t('push/error'));
			});
			
			// For foreground notifications.
			await PushNotifications.addListener('pushNotificationReceived', notification => {
				
			});
			
			// For background notifications.
			await PushNotifications.addListener('pushNotificationActionPerformed', async (input: any) => {
				if( input.notification.data && input.notification.data.url ){
					await Scene_Manager.open_url(input.notification.data.url);
					this._updater.run();
				}
			});

			Capacitor.App.addListener('resume', async () => {
				PushNotifications.removeAllDeliveredNotifications().catch(() => {});
			});
		
			await PushNotifications.register();

		}catch(e){
			console.log('Push notifications not supported.');
		}
	}

	private async _init_network()
	{
		const message_element = document.createElement('div');
		message_element.id = 'network-message';
		message_element.style.backgroundColor = 'black';
		message_element.style.bottom = '20px';
		message_element.style.color = 'white';
		message_element.style.left = '0';
		message_element.style.padding = '5px';
		message_element.style.position = 'fixed';
		message_element.style.right = '0';
		message_element.style.textAlign = 'center';
		message_element.style.zIndex = '100';
		message_element.innerText = _t('network/no_connection');

		await Network.init(this._socket);

		if( !Network.is_connected() )
			document.body.append(message_element);

		addEventListener('network_connection', () => {
			if( Network.is_connected() )
				message_element.remove();
			else
				document.body.append(message_element);
		});
	}

	private _init_scenes()
	{
		Scene_Manager.init(this._updater);

		Scene_Manager.add(new Not_Found_Scene(this._updater));

		Scene_Manager.add(new Auth_Login_Scene(this._updater));
		Scene_Manager.add(new Auth_Scene(this._updater));
		// Scene_Manager.add(new Auth_Signup_Scene(this._updater));
		Scene_Manager.add(new Auth_Verify_Email_Scene(this._updater));

		Scene_Manager.add(new Profile_Scene(this._updater));
		Scene_Manager.add(new Profile_Personal_Info_Scene(this._updater));
		Scene_Manager.add(new Profile_Preferences_Scene(this._updater));
		Scene_Manager.add(new Profile_Picture_Scene(this._updater));

		Scene_Manager.add(new Activities_Scene(this._updater));
		Scene_Manager.add(new Activities_Create_Scene(this._updater));
		Scene_Manager.add(new Activities_Discover_Scene(this._updater));
		Scene_Manager.add(new Activities_Info_Scene(this._updater));
		
		Scene_Manager.add(new Chat_Scene(this._updater));
		Scene_Manager.add(new Chat_Image_Scene(this._updater));
		Scene_Manager.add(new Chat_Info_Scene(this._updater));
		Scene_Manager.add(new Chats_Scene(this._updater));

		Scene_Manager.add(new Users_Blocked_Scene(this._updater));
		Scene_Manager.add(new Users_Friends_Scene(this._updater));
		Scene_Manager.add(new Users_Info_Scene(this._updater));
		Scene_Manager.add(new Users_Requests_In_Scene(this._updater));
		Scene_Manager.add(new Users_Requests_Out_Scene(this._updater));
		
		Scene_Manager.add(new Notifications_Scene(this._updater));
	}

	private async _init_screen()
	{
		try{
			await ScreenOrientation.lock({ orientation: 'portrait' });
		}catch(e){
			console.log('Screen orientation not supported.');
		}
	}

	private _init_socket()
	{
		this._socket = io.connect(API.uri, { auth: {token: Auth.token} });

		this._socket.on('log', (data: any) => {
			const log = {};
			parse_API_messages(log, data);
			print_global_messages(log);	
		});

		let first_connection = true;

		this._socket.on('connect', async () => {
			if( !first_connection )
				return;
			
			await Chat_Manager.init(this._socket!);
			await Notifications.init(this._socket!, this._updater);
			
			if( !Scene_Manager.active_scene )
				await Scene_Manager.open_url();
			this._updater.run();
			
			first_connection = false;
		});
	}

	private _update_bottom_menu()
	{
		const footer_element = document.getElementById('page-footer')!;
		footer_element.innerHTML = '';
		if( Scene_Manager.active_scene && Scene_Manager.active_scene.tools_element ){
			footer_element.append(Scene_Manager.active_scene.tools_element);
			footer_element.style.display = 'block';
		}else{
			footer_element.style.display = 'none';
		}
	}
}
