import { sanitize } from "dompurify"

import { Auth } from "../auth"
import { Activities, Activity } from "../activities"
import { print_date } from "../core"
import { Scene_Manager } from "../scenes"
import { Updater } from "../updater"
import { Users, User_Summary_Data, User } from "../users"
import { print_global_messages } from "./global_message"
import { Friends_Popup } from "./popups/friends_popup"
import { _t } from "../localization"
import { to_title_case } from "../core"

export class Activity_Overview
{
    activity?: Activity;
    element: HTMLDivElement;

    constructor(updater: Updater)
    {
        this.element = document.createElement('div');
        this._updater = updater;
    }

    update(activity: Activity)
    {
        if( !Auth.current_user )
            return;

        this.activity = activity;

        let datetime = this.activity.datetime ? print_date(this.activity.datetime) : '';
        if( !datetime )
            datetime = '';

        let deadline = this.activity.deadline ? print_date(this.activity.deadline) : '';
        if( !deadline )
            deadline = '';

        const free_label_code = activity.paid ? '' : `<div class="activity-label paid-label">${_t('activities/free')}</div>`;
        
        let gender_label_code =  '';
        if( activity.gender === 'male' )
            gender_label_code = `<div class="activity-label gender-label">${to_title_case(_t('activities/men_only'))}</div>`;
        else if( activity.gender === 'female' )
            gender_label_code = `<div class="activity-label gender-label">${to_title_case(_t('activities/women_only'))}</div>`;

        let invitation_label_code = '';
        if( activity.max_invitees === 1 )
            invitation_label_code = `<div class="activity-label invitation-label">${_t('activities/label/one_friend')}</div>`;
        else if( activity.max_invitees > 1 )
            invitation_label_code = `<div class="activity-label invitation-label">${_t('activities/label/multiple_friends', Number(activity.max_invitees))}</div>`;

        let label_code = free_label_code + gender_label_code + invitation_label_code;
        if( label_code )
            label_code = `<div class="labels">${label_code}</div>`;

        let spot_code = ``;
        if( activity.deadline && new Date() < activity.deadline ){
            const available_spots = activity.guest_count ? activity.max_guests - activity.guest_count : activity.max_guests;
            if( !available_spots )
                spot_code += `; <span>${_t('activities/spots/no_spot_left')}</span>`;
            else if( available_spots === 1 )
                spot_code += `; <span class="${activity.action && activity.action.type === 'joined' ? '' : 'danger'}">${_t('activities/spots/one_spot_left')}</span>`;
            else
                spot_code += `; <span>${_t('activities/spots/multiple_spots_left', Number(available_spots))}</span>`;
        }

        let guests_code = '';
        if( activity.min_guests === activity.max_guests )
            guests_code += Number(activity.min_guests).toString();
        else
            guests_code += _t('activities/guest_count', Number(activity.min_guests), Number(activity.max_guests));
        
        guests_code = `
            <div class="guests">
                <strong>${_t('activities/number_of_guests')}</strong>
                <span class="value">${guests_code}${spot_code}</span>
            </div>`;

        // First check you own action, than the status as the status can change after your action.
        let note_code = '';
        if( activity.action && activity.action.type === 'left' )
            note_code = `<div><strong class="error">${_t('activities/left')}</strong></div>`;
        else if( activity.action && activity.action.type === 'expired' )
            note_code = `<div><strong class="error">${_t('activities/expired')}</strong></div>`;
        else if( activity.status === 'canceled' )
            note_code = `<div><strong class="error">${_t('activities/canceled')}</strong></div>`;

        let code = `
        <div class="activity-overview">
            ${label_code}
            <div class="activity-header">
                <div class="name">${sanitize(activity.name)}</div>
                <div class="meta">
                    ${sanitize(note_code)}
                    <div><strong>${_t('activities/date')}</strong> <strong class="value">${sanitize(datetime)}</strong></div>
                    <div><strong>${_t('activities/deadline')}</strong> <span class="value">${sanitize(deadline)}</span></div>
                    <div><strong>${_t('activities/location')}</strong> <span class="value">${sanitize(activity.address + ' ' + activity.city)}</span></div>
                    ${sanitize(guests_code)}
                </div>
            </div>
            <hr/>

            <div style="white-space: pre-line">${sanitize(activity.description)}</div>
            <hr/>`;

        if(
            activity.deadline && new Date() < activity.deadline && (
                activity.user && Auth.current_user.uid === activity.user.uid ||
                activity.max_invitees !== 0 && activity.action && activity.action.type === 'joined'
            )
        )
            code += '<button class="add-member add-button button round primary" style="float: right; margin-bottom: 20px;">+</button>';

        code += `
            <h2>${_t('activities/people_you_know')}</h2>
            <div class="user-section"></div>
            <hr/>
        </div>`;

        this.element.innerHTML = code;

        const overview_element = this.element.querySelector('.activity-overview')!;
        const user_section = overview_element.querySelector('.user-section')!;

        if( activity.user || activity.guests.length > 0 ){
            let user_code = `
            <table class="full-width text-left activity-user-table">`;
                if( activity.user ){
                    user_code += `
                <tr>
                    <th><strong>${_t('activities/host')}</strong></th>
                    <td class="host-image-section"></td>
                </tr>`;
                }
                if( activity.guests.length > 0 ){
                    user_code += `
                <tr>
                    <th><strong>${_t('activities/guests')}</strong></th>
                    <td class="guests-image-section"></td>
                </tr>`;
                }
                user_code += `
            </table>`;

            const users_element = document.createElement('div');
            users_element.innerHTML = user_code;
            user_section.append(users_element);

            if( activity.user ){
                const host_section_element = users_element.querySelector('.host-image-section') as HTMLElement;
                this._print_users(host_section_element, activity.user);
            }

            if( activity.guests.length > 0 ){
                const guests_section_element = users_element.querySelector('.guests-image-section') as HTMLElement;
                this._print_users(guests_section_element, activity.guests);
            }
        }else{
            const message_element = document.createElement('p');
            message_element.className = 'text-center';
            message_element.innerHTML = _t('general/nothing_found');
            user_section.append(message_element);
        }

        const add_member_button = this.element.querySelector('.add-member') as HTMLButtonElement;
        if( add_member_button )
            add_member_button.addEventListener('click', this._on_add_member_click.bind(this));
    }

    private _updater: Updater;

    private _on_add_member_click()
    {
        if( !this.activity )
            return;

        const excludes = this.activity.guests.map(guest => guest.uid);
        if( this.activity.user )
            excludes.push(this.activity.user.uid);
        
        const friends_popup = new Friends_Popup(_t('general/invite'), this._on_friends_to_add_selected.bind(this), {'exclude': excludes});
        friends_popup.open();
    }

    private async _on_friends_to_add_selected(friends: User[])
    {
        if( !this.activity )
            return;

        const log = {};

        for(const friend of friends)
            await Activities.invite(this.activity, friend, log);

        print_global_messages(log);

        this._updater.run();
    }

    private _print_users(parent: HTMLElement, data: User_Summary_Data | User_Summary_Data[])
    {
        if( !Array.isArray(data) )
            data = [data];

        data.forEach(data => {
            const element = document.createElement('img');
            element.className = 'user-image framed-image round ' + data.relation;
            element.dataset.uid = data.uid;
            element.src = Users.get_image_uri(data.image, 'info_item');
            parent.append(element);

            if( data.uid !== Auth.current_user?.uid ){
                element.classList.add('clickable');
                element.addEventListener('click', async activity => {
                    const user = await Users.get((activity.target as HTMLImageElement).dataset.uid!)
                    if( user ){
                        await Scene_Manager.open('user', {'uid': user.uid});
                        this._updater.run();
                    }else{
                        alert(_t('users/could_not_load_user'));
                    }
                })
            }
        });
    }
}