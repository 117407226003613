import { _t } from "../../localization";
import { Popup } from "./popup";

export class HTML_Popup extends Popup
{
    constructor(name: string, title: string, content: HTMLElement)
    {
        super(name, { title: title });

        this._body.append(content);
    }
}
