import { _t } from "../../localization";
import { Popup } from "./popup";

export class Input_Popup extends Popup
{
    constructor(name: string, title: string, type: 'text', value: string, callback: (value: string) => void)
    {
        super(name, { title: title });

        this._callback = callback;

        this._input = document.createElement('input');
        this._input.className = 'full-width';
        this._input.type = type;
        this._input.style.marginTop = '10px';
        this._input.value = value;
        this._body.appendChild(this._input);

        this._confirm_button = document.createElement('button');
        this._confirm_button.className = 'primary full-width';
        this._confirm_button.innerHTML = _t('general/confirm');
        this._confirm_button.style.marginTop = '10px';
        this._confirm_button.addEventListener('click', this._on_confirm.bind(this));
        this._body.appendChild(this._confirm_button);
    }

    private _callback: (value: string) => void;
    private _confirm_button: HTMLButtonElement;
    private _input: HTMLInputElement;

    private async _on_confirm()
    {
        this._callback(this._input.value);
    }
}
