import * as io from "socket.io-client";
import { Network as Capacitor_Network, ConnectionStatus } from '@capacitor/network';

export class Network
{
    static async init(socket?: io.Socket)
    {
        this._socket = socket;

        const status = await Capacitor_Network.getStatus();
        this._is_connected = status.connected;

        if( this._socket){
            this._socket.on('connect', this._on_connection_change.bind(this, true));
            this._socket.on('disconnect', this._on_connection_change.bind(this, false));
        }else{
            Capacitor_Network.addListener('networkStatusChange', async status => {
                this._on_connection_change(status.connected);
            });
        }
    }

    static is_connected(): boolean
    {
        return this._is_connected;
    }

    static _is_connected = false;
    static _socket?: io.Socket;

    static _dispatch(status: ConnectionStatus)
    {
        dispatchEvent(
           new CustomEvent('network_connection', { detail: {
               is_connected: status.connected,
               type: status.connectionType}
           })
        );
    }

    static _on_connection_change(is_connected: boolean): void
    {
        if( this._is_connected === is_connected )
            return;

        this._is_connected = is_connected;
        
        dispatchEvent(
            new CustomEvent('network_connection', { detail: {
                is_connected: is_connected
            }})
        );
    }
}