import { sanitize } from "dompurify";

import { Activity, Activities } from "../activities";
import { Auth } from "../auth";
import { Chat_Manager } from "../chats";
import { Scene, Scene_Manager } from "../scenes";
import { Activity_Overview } from "../ui/activity_overview";
import { print_global_message, print_global_messages } from "../ui/global_message";
import { Updater } from "../updater";
import { _t } from "../localization";

export class Activities_Info_Scene extends Scene
{
    activity?: Activity;

    constructor(updater: Updater)
    {
        super(updater, 'activity');

        this.user_state = 'active';
        this._activity_overview = new Activity_Overview(updater);

        this.element.className = 'page-section';
        
        this._header_element = document.createElement('div');
        this._header_element.style.backgroundSize = 'cover';
        this._header_element.style.height = '200px';
        this._header_element.style.width = '100%';

        this.element.append(this._activity_overview.element);

        this._init_tool_element();
    }

    async open(args?: {[key: string]: string})
    {
        await super.open(args);
        
        if( args && args.uid )
            this.activity = await Activities.get(args.uid);
        else
            this.activity = undefined;
    }

    async update()
    {
        await this._print();
        this._update_tools();
    }

    _activity_overview: Activity_Overview;
    _header_element: HTMLDivElement;
    _message_button!: HTMLElement;

    private _init_tool_element()
    {
        this.tools_element = document.createElement('div');

        this.tools_element.className = "bottom-menu flex-equal page-center-content";
        this.tools_element.innerHTML += `
            <a class="chat-button button">
                <img src="/assets/images/nav-chats.png">
            </a>`;
        
        this._message_button = this.tools_element.querySelector('.chat-button') as HTMLElement;
        this._message_button.addEventListener('click', async () => {
            if( !this.activity || !this.activity.chat )
                return;

            await Scene_Manager.open('chat', {'uid': this.activity.chat});
            this._updater.run();
        });
    }

    private async _on_cancel_click()
    {
        if( !this.activity || !confirm(_t('activities/cancel_confirm')) )
            return;

        const log = {};
        const activity = await Activities.cancel(this.activity, log);
        print_global_messages(log);
        if( !activity )
            return;

        this.activity = activity;
        
        await Scene_Manager.open('activities');
        this._updater.run();
    }

    private async _on_decline_click()
    {
        if( !this.activity || !confirm('Do you want to decline permanently?') )
            return;

        const log = {};
        const result = await Activities.decline(this.activity, undefined, log);
        print_global_messages(log);
        if( !result )
            return;

        this.activity = result;
        
        await Scene_Manager.open('activities');
        
        this._updater.run();
    }

    private async _on_join_click()
    {
        if( !this.activity )
            return;

        const log = {};
        const activity = await Activities.join(this.activity, undefined, log);
        print_global_messages(log);
        if( !activity )
            return;

        this.activity = activity;

        setTimeout(async () => {
            if( await Chat_Manager.get(this.activity!.chat) ){
                await Scene_Manager.open('chat', {'uid': this.activity!.chat});
            }else{
                print_global_message('error', "Couldn't immediately open chat. Please try again.");
                await Scene_Manager.open('chats');
            }
            
            this._updater.run();
        }, 1000);

        this._updater.run();
    }

    private async _on_leave_click()
    {
        if( !this.activity || !confirm('Are you sure you want to leave?') )
            return;

        const log = {};
        const activity = await Activities.leave(this.activity, log);
        print_global_messages(log);
        if( !activity )
            return;

        this.activity = activity;

        await Scene_Manager.open('activities');
        this._updater.run();
    }

    private async _print()
    {
        this.element.innerHTML = '';

        if( !Auth.current_user || !this.activity )
            return;

        this._header_element.style.backgroundImage = this.activity.image ? `url('${sanitize(this.activity.image)}')` : '';
        this._header_element.style.backgroundPosition = 'center';
        if( !this._header_element.parentElement && this.element.parentElement )
            this.element.parentElement.prepend(this._header_element);

        this._activity_overview.update(this.activity);
        this.element.append(this._activity_overview.element);

        this._print_action_buttons();
    }

    private async _print_action_buttons()
    {
        if( !Auth.current_user || !this.activity )
            return;

        if( !this.activity.datetime || Date.now() > this.activity.datetime.getTime() )
            return;

        if(  this.activity.status !== 'active' )
            return;

        if( this.activity.user?.uid === Auth.current_user.uid ){

            const cancel_button = document.createElement('button');
            cancel_button.className = 'full-width danger';
            cancel_button.innerHTML = _t('general/cancel');
            cancel_button.addEventListener('click', this._on_cancel_click.bind(this));
            this.element.append(cancel_button);

        }else if( this.activity.action && this.activity.action.type === 'joined' ){

            const leave_button = document.createElement('button');
            leave_button.className = 'full-width danger';
            leave_button.innerHTML = _t('activities/leave');
            leave_button.addEventListener('click', this._on_leave_click.bind(this));
            this.element.append(leave_button);

        }else if( this.activity.action && (this.activity.action.type === 'invited' || this.activity.action.type === 'saved') ){

            const buttons_element = document.createElement('div');
            buttons_element.className = 'flex-equal horizontal-rounded-content';
            this.element.append(buttons_element);

            const join_button = document.createElement('button');
            join_button.className = 'primary';
            join_button.innerHTML = _t('activities/join');
            join_button.addEventListener('click', this._on_join_click.bind(this));
            buttons_element.append(join_button);

            const decline_button = document.createElement('button');
            decline_button.innerHTML = _t('activities/decline');
            decline_button.addEventListener('click', this._on_decline_click.bind(this));
            buttons_element.append(decline_button);
        }
    }

    private async _update_tools()
    {
        this._message_button.style.display =
            this.activity && this.activity.chat && await Chat_Manager.get(this.activity.chat)
            ? 'block'
            : 'none';
    }
}
