import { PushNotifications } from '@capacitor/push-notifications';

import globals      from "./.env";
import { Auth }     from "./auth";
import { _t } from "./localization";
import { print_global_message } from "./ui/global_message";
import { Updater }  from "./updater";

export class Scene
{
    element: HTMLDivElement;
    menu_type: '' | 'nav' | 'language' = 'nav';
    is_active = false;
    tools_element?: HTMLDivElement;
    path: string;
    user_state: '' | 'logged_in' | 'logged_out' | 'active' = '';

    constructor(updater: Updater, path: string)
    {
        this.path = path;
        this._updater = updater;

        this.element = document.createElement('div');
    }

    close()
    {
        this.is_active = false;
    }

    async open(args?: Record<string, string>)
    {
        this.is_active = true;

        PushNotifications.removeAllDeliveredNotifications().catch(_ => {});
    }

    async update() {};

    protected _updater: Updater;
}

export class Scene_Manager
{
    static active_scene ?: Scene;
    static referrer = '';

    static init(updater: Updater)
    {
        Scene_Manager._updater = updater;

        addEventListener('popstate', Scene_Manager._on_pop_state.bind(this));
    }

    static add(scene: Scene)
    {
        this._scenes.set(scene.path, scene);
    }

    static async open(path: string, args?: {[key: string]: string})
    {
        if( !await this._set_scene(path, args) )
            return;

        const query = new URLSearchParams(args);

        let url = this._base_uri + path;
        if( query.size )
            url += '?' + query.toString();

        history.pushState({}, '', url);
    }

    static async open_url(url = '')
    {
        let final_path = Auth.current_user ? globals.HOME_PATH : globals.AUTH_PATH;
		let final_query: Record<string, string> = {};
		
		if( !url )
			url = location.href;

		const url_data = new URL(url);
	
		let path = url_data.pathname.split('.com').pop(); // `pathname` doesn't work with custom protocols. So we find the ourselves.
		if( path && path[0] === '/' )
			path = path.slice(1);

		if( path ){
			const query: Record<string, string> = {};
			url_data.searchParams.forEach((value, key) => query[key] = value);

			final_path = path;
			final_query = query;
		}
		
		await Scene_Manager.open(final_path, final_query);
    }

    static go_back()
    {
        history.back();
    }

    static async update()
    {
        if( this.active_scene )
            await this.active_scene.update();
    }
    
    private static _base_uri = '/';
    private static _scenes   = new Map<string, Scene>;
    private static _updater: Updater;

    private static _generate_url(path: string, args: Record<string, string> = {})
    {
        return location.origin + this._base_uri + path + '?' + new URLSearchParams(args).toString();
    }

    private static async _on_pop_state()
    {
        await this._set_scene(
            location.pathname.substring(this._base_uri.length),
            Object.fromEntries(new URLSearchParams(location.search))
        );

        this._updater.run();
    }

    private static async _set_scene(path: string, args: Record<string, string> = {}): Promise<boolean>
    {
        path = path.replace(/^\//, '');
        if( !path )
            path = globals.HOME_PATH;

        let redirect: {path: string, args?: Record<string, string>} | undefined;

        let new_scene = this._scenes.get(path);
        if( !new_scene )
            new_scene = this._scenes.get('not_found')!; 
        
        if( new_scene.user_state === 'active' ){
            if( Auth.current_user ){
                if( Auth.current_user.status === 'unverified' ){
                    if( !Auth.current_user.verified.includes('email') ){
                        redirect = { path: 'auth/verify_email' };
                    }else if( !Auth.current_user.verified.includes('profile') ){
                        redirect = { path: 'profile/personal_info', args: {'is_setup': 'true'} };
                    }else if( !Auth.current_user.verified.includes('image') ){
                        redirect = { path: 'profile/picture', args: {'is_setup': 'true'} };
                    }else{
                        print_global_message('error', _t('users/verification_error'));
                        return false;
                    }
                }else if( Auth.current_user.status !== 'active' ){
                    redirect = { path: globals.AUTH_PATH, args: {'redirect': this._generate_url(path, args)} };
                }
            }else{
                redirect = { path: globals.AUTH_PATH, args: {'redirect': this._generate_url(path, args)} };
            }
        }else if( new_scene.user_state === 'logged_in' ){
            if( !Auth.current_user || Auth.current_user.status !== 'active' && Auth.current_user.status !== 'unverified' )
                redirect = { path: globals.AUTH_PATH, args: {'redirect': this._generate_url(path, args)} };
        }else if( new_scene.user_state === 'logged_out' && Auth.current_user ){
            redirect = { path: globals.HOME_PATH };
        }

        if( redirect ){
            await this.open(redirect.path, redirect.args);
            return false;
        }
        
        if( this.active_scene ){
            this.referrer = this.active_scene.path;
            this.active_scene.close();
        }

        this.active_scene = new_scene;
        await this.active_scene.open(args);

        const parent_element = document.getElementById('page-body-content')!;
        parent_element.innerHTML = '';
        parent_element.append(this.active_scene.element);

        return true;
    }
}
